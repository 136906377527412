// =============================================================================
// Variables
// =============================================================================
$banner-height: 17.6rem;
$banner-height-desktop: 28.3rem;
$banner-height-desktop-large: 45.6rem;

// =============================================================================
// Root
// =============================================================================

// =============================================================================
// Elements
// =============================================================================
.c-banner__bar {
  width: 100%;
  height: $banner-height;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;

  @screen tablet {
    height: $banner-height-desktop;
  }

  div.c-banner__content--cta {
    .c-banner__intro {
      display: none;
      @screen tablet {
        margin-top: 0.8rem;
        display: block;
      }
    }

    .c-banner__button {
      margin-top: 0.8rem;
    }

    div.c-banner__title__subtitle {
      justify-content: center;

      .c-banner__title {
        @apply h2-base;
      }

      .c-banner__boxtitle {
        display: none;
      }
    }
  }

  .c-banner__content--default {
    .c-banner__title__subtitle {
      justify-content: center;

      .c-banner__title {
        @apply h6-uppercase;
      }

      .c-banner__boxtitle {
        @apply h6-uppercase;
        color: theme('colors.grayscales.white');
        width: fit-content;
        margin-top: 0.8rem;
        padding: 1.1rem 1.8rem;
        background-color: theme('colors.grayscales.gray3');
      }
    }

    .c-banner__intro,
    .c-banner__button {
      display: none;
    }
  }

  .c-banner__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    background-color: theme('colors.grayscales.white');
    min-width: 12.6rem;
    align-items: flex-start;
    margin: 0;
    position: relative;

    @screen tablet {
      padding-left: 2rem;
      min-width: 45.6rem;
      max-width: 55.6rem;
    }

    &::after {
      content: '';
      position: absolute;
      border: 0 solid transparent;
      right: -6rem;
      border-left-width: 6rem;
      border-top-width: $banner-height;
      border-left-color: theme('colors.grayscales.white');

      @screen tablet {
        border-top-width: $banner-height-desktop;
      }
    }

    > div {
      margin: 0 auto;
    }
  }
}

// =============================================================================
// States
// =============================================================================

.c-banner__bar-large {
  @screen tablet {
    height: $banner-height-desktop-large;

    .c-banner__content {
      &::after {
        border-top-width: $banner-height-desktop-large;
      }
    }
  }
}

.c-banner--default-color {
  background-color: theme('colors.primary.dark');
  position: relative;
  overflow: hidden;

  @screen tablet {
    &:after {
      content: url($globals-svg-path + 'flanders-logo.svg');
      position: absolute;
      right: 0;
      width: 20rem;
      opacity: 0.2;
      align-self: center;
    }
  }
}

.c-banner__content {
  &--image-only {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    padding: 0 0 2.8rem 0;
    margin: 0 1.7rem;

    height: $banner-height;
    width: 100%;

    @screen tablet {
      padding: 0 0 5.6rem 0;
      margin: 0 0 0 10rem;

      height: $banner-height-desktop;
      width: fit-content;
      max-width: 55.6rem;
    }
  }

  &__title--image-only {
    background-color: theme('colors.grayscales.white');
    width: 100%;

    h1 {
      color: theme('colors.grayscales.gray2');

      padding: 1.2rem;
      margin: 0;

      width: fit-content;
    }
  }
}
