// =============================================================================
// Root
// =============================================================================
.c-datepicker {
  display: flex;

  align-items: flex-start;
  flex-flow: column;
  width: 100%;
}

.c-datepicker__container:focus-visible {
  box-shadow: none;
  outline: 3px solid theme('colors.cta.outline');
  outline-offset: 2px;
}

// =============================================================================
// Elements
// =============================================================================
.c-datepicker__label {
  margin-right: 0.5rem;
}

.c-datepicker__container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.c-datepicker__icon {
  @apply fontawesome-base;
  @apply fontawesome-medium;
  color: theme('colors.cta.base');
  min-width: 5rem;
  height: 4.6rem;
  border: 0.1rem solid theme('colors.grayscales.gray4');
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -0.1rem;
}

input.c-datepicker__container,
.c-datepicker__input {
  @apply paragraph-base;

  padding: 1rem 1.3rem;

  width: inherit;

  border: 0.1rem solid theme('colors.grayscales.gray4');
}

// =============================================================================
// Styling Flatpickr
// =============================================================================

.flatpickr-calendar {
  width: 26rem;

  &:before,
  &:after {
    //zolang positionering niet goed is van de datepicker, geen arrow.
    display: none;
  }

  .flatpickr-monthSelect-month.selected {
    @apply paragraph-bold;

    background-color: theme('colors.grayscales.gray5');
    color: theme('colors.grayscales.gray1');
  }
}

.flatpickr-monthSelect-month:hover {
  background-color: theme('colors.grayscales.gray6');
}

.flatpickr-rContainer {
  width: 100%;
}

.flatpickr-months {
  height: 5rem;
  align-items: center;
  border-bottom: 0.1rem solid theme('colors.grayscales.gray4');

  .flatpickr-month {
    height: inherit;
    line-height: inherit;
    position: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    .flatpickr-current-month {
      height: auto;
      width: 100%;
      font-size: inherit;
      position: inherit;
      padding: 0;
      line-height: inherit;

      .numInputWrapper {
        width: 5.5rem;
      }

      .flatpickr-monthDropdown-months {
        -webkit-appearance: none;
        width: 10rem;
        padding-left: 0.4rem;
        &:hover {
          -webkit-appearance: searchfield;
          padding: 0;
        }
      }
    }
  }
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month .numInputWrapper .numInput {
  @apply paragraph-bold;
  color: theme('colors.cta.base');
}

.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
  visibility: hidden;
  height: inherit;
  display: flex;
  align-items: center;
}

.flatpickr-next-month:after,
.flatpickr-prev-month:before {
  @apply fontawesome-base;
  width: 3rem;
  height: 3rem;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: theme('colors.grayscales.gray5');
  color: theme('colors.grayscales.gray2');
  visibility: visible;

  border-radius: 2rem;
}

.flatpickr-next-month:hover::after,
.flatpickr-prev-month:hover::before {
  background-color: theme('colors.grayscales.gray4');
}

.flatpickr-next-month:after {
  content: '\f054';
}

.flatpickr-prev-month:before {
  content: '\f053';
}

span.flatpickr-weekday {
  @apply h7-base;
  color: theme('colors.grayscales.gray1');
}

.flatpickr-days {
  width: 100%;
  .dayContainer {
    width: 100%;
    min-width: inherit;
    max-width: inherit;
  }
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  @apply small-bold;
  border-color: theme('colors.flemish.dark');
  background-color: theme('colors.flemish.dark');
  color: theme('colors.grayscales.gray2');
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 theme('colors.flemish.dark');
}

.flatpickr-day {
  @apply small-base;
  display: flex;
  align-items: center;

  width: 3rem;
  max-width: 3rem;
  height: 3rem;

  margin: 0 0.3rem;

  &.today {
    border-color: theme('colors.flemish.dark');
  }

  &:hover,
  &.today:hover,
  &.selected:hover {
    @apply small-bold;
    border-color: theme('colors.grayscales.gray5');
    background-color: theme('colors.grayscales.gray5');
    color: theme('colors.cta.base');
  }
}

// =============================================================================
// Modifiers
// =============================================================================

// =============================================================================
// States
// =============================================================================
.c-datepicker {
  &.is-error {
    input.c-datepicker__container,
    .c-datepicker__icon {
      border: 0.1rem solid theme('colors.ui.red1');

      background-color: theme('colors.ui.red3');

      &:focus {
        outline: none;
        border: 0.1rem solid theme('colors.ui.orange1');

        background-color: theme('colors.grayscales.white');
      }
    }
  }

  &.is-disabled {
    pointer-events: none;

    .c-datepicker__container {
      background-color: theme('colors.grayscales.gray5');
    }

    .c-datepicker__icon {
      color: theme('colors.grayscales.gray3');
    }
  }
}

.c-datepicker__input-readonly {
  pointer-events: none;

  @apply paragraph-bold;

  color: theme('colors.grayscales.gray2');
}
