// =============================================================================
// Imports
// =============================================================================

// =============================================================================
// Root
// =============================================================================

.c-action-list {
  margin-bottom: -0.4rem;
}

.c-action-list-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.4rem;
  padding: 1.5rem;
  background-color: theme('colors.grayscales.gray5');
}

// =============================================================================
// Elements
// =============================================================================
.c-action-list-item__main {
  display: flex;
  flex-direction: row;

  width: 100%;

  justify-content: space-between;
}
.c-action-list-item__detail {
  display: flex;
  flex-direction: row;

  width: 100%;

  justify-content: space-between;
  padding-top: 0.5rem;
}

.c-action-list-item__content {
  flex: 100;
  &-title {
    @apply paragraph-bold;
  }
}

.c-action-list-item__action {
  text-align: right;
  align-self: center;
  padding: 0 2rem;
  line-height: 3.4rem;
  min-height: 3.4rem;

  cursor: pointer;

  > * {
    margin-right: 3.2rem;
  }

  > *:last-child {
    margin-right: 0;
  }

  i {
    color: theme('colors.cta.base');
    &:hover {
      color: theme('colors.cta.dark');
    }
  }
}

// =============================================================================
// Modifiers
// =============================================================================

// =============================================================================
// States
// =============================================================================
