// =============================================================================
// Imports
// =============================================================================
@import 'navigation-settings.scss';

// =============================================================================
// Root
// =============================================================================
.c-navigation {
  @screen tablet {
    width: $navigation-desktop-width;
    height: 100%;
  }

  @screen toTablet {
    position: fixed;
    z-index: $z-index-500;
    bottom: 0;
    left: 0;

    width: 100vw;
    width: -webkit-fill-available;

    &:not(.mobile-collapsed) {
      width: 100%;
      height: 50vh;
    }
  }
}

.desktop-collapsed {
  @screen mobile {
    width: fit-content;
  }
}

// =============================================================================
// Elements
// =============================================================================

.c-navigation__nav {
  height: 100%;

  display: flex;
  flex-direction: column;

  ol {
    list-style: none;
    padding-left: 0;
  }

  /***************************************************************************************************
  * HEADER BAR FOR MOBILE NAVIGATION
  */

  .c-navigation__header-mobile {
    height: $navigation-item-height;

    background-color: theme('colors.grayscales.gray6');

    order: 1;

    @screen tablet {
      display: none;
    }
  }

  .c-navigation__collapsable {
    height: 100%;
    background-color: theme('colors.grayscales.white');
    overflow: auto;

    &--slide-up {
      -webkit-animation: slide-up 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: slide-up 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }

    @screen mobile {
      animation: none;
    }
  }

  /***************************************************************************************************
  * SCROLLABLE AREA WITH NAVIGATION ITEMS
  */

  .c-navigation__scroll {
    flex: 100;
    order: 3;

    overflow: auto;
    display: flex;
    flex-direction: column;

    background-color: theme('colors.grayscales.white');
    @screen tablet {
      background-color: theme('colors.grayscales.gray6');
    }

    .c-navigation__fill {
      flex: 100;
      flex-grow: 100;
    }
    @screen toTablet {
      .c-navigation__fill {
        display: none;
      }
    }
    @screen tablet {
      height: 100%;
      .c-navigation__footer-items {
        border-top: 0.1rem solid theme('colors.cta.base');
        background-color: theme('colors.grayscales.gray5');
      }
    }
    ol {
      list-style: none;
      padding-left: 0;
    }
  }

  /***************************************************************************************************
  * TOGGLE BAR: BAR WITH BUTTON TO TOGGLE THE NAVIGATION
  *   - For desktop (top): Title for the menu and if collapsible button to toggle
  *   - For mobile (bottom): Area with icons for fast accessible menu items + button to toggle menu
  */

  .c-navigation__toggle {
    height: $navigation-item-height;
    order: 3;

    background-color: theme('colors.grayscales.gray6');

    @screen tablet {
      background-color: theme('colors.grayscales.gray5');
      border-bottom: 0.1rem solid theme('colors.cta.base');
    }
  }

  @screen tablet {
    .c-navigation__toggle--mobile {
      display: none;
    }
  }
  @screen toTablet {
    .c-navigation__toggle--desktop {
      display: none;
    }
  }
}

.c-navigation-item__wrapper {
  @screen toTablet {
    border-bottom: 0.1rem solid theme('colors.grayscales.gray5');
  }
}

.c-navigation-item {
  @apply h4-uppercase;
  height: $navigation-item-height;
  line-height: $navigation-item-height;

  display: flex;
  flex-direction: row;

  cursor: pointer;

  ul {
    padding: 0;
  }

  .c-navigation-item__label {
    flex: auto;

    display: block;
    position: relative;

    color: theme('colors.cta.base');
    height: $navigation-item-height;
    line-height: 2.4rem;

    @screen toTablet {
      color: theme('colors.grayscales.gray1');
    }

    .c-navigation-item__label__link-label {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 0%;
      transform: translate(0%, -50%);

      @screen toTablet {
        left: 2rem;
        @apply paragraph-base;
        text-transform: none;
      }
    }
  }

  /** online used for mobile nav, area with icons for quick navigation => visible in bottom bar */

  .c-navigation-item__mobile-quicklinks {
    flex: 100;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .c-navigation-item__square {
    position: relative;
    height: $navigation-item-height;
    line-height: $navigation-item-height;
    width: $navigation-item-height;

    text-align: center;

    .c-navigation-item__square__icon {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0 -50% 0 0;
      transform: translate(-50%, -50%);

      .c-navigation-item__square__icon__top {
        font-size: 2.4rem;
        line-height: 1.5rem;
      }
      .c-navigation-item__square__icon__bottom {
        padding-top: 0.4rem;
        line-height: 1.5rem;
        text-transform: none;
        @apply small-bottom-nav;
      }

      i {
        .c-navigation-item__badge {
          position: absolute;

          bottom: 1.7rem;
          right: -0.2rem;

          @screen tablet {
            bottom: 0.7rem;
            right: -1rem;
          }
          .c-badge {
            line-height: 2rem;
            min-width: 2rem;
            min-height: 2rem;
            border-radius: 1rem;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

.c-navigation-item__submenu {
  list-style-type: none;
}

.c-navigation-sub-item__label {
  text-transform: none;
}

.c-navigation__toggle--mobile {
  @screen toTablet {
    z-index: $z-index-base;
    -webkit-box-shadow: 0rem -0.4rem 1rem rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0rem -0.4rem 1rem rgba(0, 0, 0, 0.25);
    box-shadow: 0rem -0.4rem 1rem rgba(0, 0, 0, 0.25);
  }
}

.c-navigation-sub-item {
  @apply h5-light-uppercase;
  height: $navigation-sub-item-height;

  display: flex;
  flex-direction: row;

  cursor: pointer;
  color: theme('colors.cta.base');
  @screen toTablet {
    color: theme('colors.grayscales.gray2');
  }

  &:hover {
    font-weight: 500;
  }
}

.c-navigation__header-items {
  height: $navigation-item-height;

  border-bottom: 0.1rem solid theme('colors.cta.base');
}

.c-navigation__items {
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;

  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

.c-navigation-item__mobile-quicklinks-item {
  color: theme('colors.grayscales.gray3');
}

.c-navigation__header-mobile {
  position: sticky;
  top: 0;
  z-index: $z-index-200;
  .c-navigation-item {
    @apply paragraph-bold;
    text-transform: none;
    line-height: 6rem;
  }
}

// =============================================================================
// Modifiers
// =============================================================================

.c-navigation__nav--collapsed {
  @screen tablet {
    width: $navigation-item-height;
    min-width: $navigation-item-height;
  }
}

.c-navigation-item--cta {
  background-color: theme('colors.cta.base');
  color: theme('colors.grayscales.white');
}

.c-navigation-item--cta--open {
  background-color: theme('colors.cta.dark');
}

// =============================================================================
// States
// =============================================================================

.c-navigation.mobile-collapsed {
  @screen toTablet {
    .c-navigation__collapsable {
      &--collapsed {
        height: 0;
      }

      &--slide-down {
        -webkit-animation: slide-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          both;
        animation: slide-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
    }
  }
}

.desktop-collapsed {
  .c-navigation__nav .c-navigation__collapsable, .c-navigation__nav .c-navigation__scroll {
    overflow: visible !important;
  }

  .c-navigation-item__wrapper {
    position: relative;
  }
  .c-navigation-item__icon-toggle-item, .c-navigation-item .c-navigation-item__label {
    display: none !important;
  }

  .c-navigation-item__wrapper:hover {
    .c-navigation-item__submenu {
      display: block;
    }
  }
  .c-navigation-item__submenu {
    display: none;
    position: absolute;
    left: 6rem;
    top: 3rem;
    z-index: 999;
    background-color: theme('colors.grayscales.white');
  }

  .c-navigation-item--active,
  .c-navigation-item__submenu--active {
    border-left: none;

  }

  ul.c-navigation-item__submenu {
    padding-inline-start: 0.5rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 2rem;
    min-width: 20rem;

    box-shadow: 0 1rem 1.5rem -0.3rem rgba(26, 32, 44, 0.1),
    0 0.4rem 0.6rem -0.2rem rgba(26, 32, 44, 0.05);
  }
  .c-navigation-item__square {
    position: relative;
  }

  .c-navigation-item__arrow {
    display: block;
    position: absolute;
    font-size: 1rem;
    bottom: 0.5rem;
    right: 0.5rem;
    color: theme('colors.grayscales.gray3');
  }

}


.c-navigation-item__mobile-quicklinks-item--active {
  color: theme('colors.cta.base');
}

.c-navigation {
  &__overlay {
    height: -webkit-fill-available;
    height: 100vh;
    width: 100vw;
    width: -webkit-fill-available;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-index-400;
    opacity: 0.3;
    background-color: theme('colors.grayscales.gray1');

    @screen tablet {
      display: none;
    }
  }
}

.c-navigation-item--active,
.c-navigation-item__submenu--active {
  background-color: theme('colors.grayscales.white');
  border-left: 0.4rem solid theme('colors.cta.base');

  .c-navigation-item__label__link-label {
    color: theme('colors.cta.base');
  }
}
.c-navigation-sub-item--active {
  color: theme('colors.cta.base');
}
.c-navigation__header-mobile__logo-img {
  width: 6.8rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 4.5rem;
    height: $navigation-item-height;
    background-color: theme('colors.flemish.dark');
    transition: background 0.1s ease-in-out;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 4.5rem;
    width: 0;
    height: 0;
    border-top: $navigation-item-height solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 1.6rem solid theme('colors.flemish.dark');
    transition: border-color 0.1s ease-in-out;
  }

  svg {
    position: relative;
    text-decoration: none;
    font-weight: 500;
    color: inherit;

    width: 3.6rem;
    height: 3.6rem;
    left: 0.8rem;
    top: 1.2rem;
    margin-right: 1.7rem;
    fill: #333332;
  }
}

/**
 * ----------------------------------------
 * animation slide-up
 * ----------------------------------------
 */
@-webkit-keyframes slide-up {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-up {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

/**
 * ----------------------------------------
 * animation slide-down
 * ----------------------------------------
 */
@-webkit-keyframes slide-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    height: 0;
  }
}
@keyframes slide-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    height: 0;
  }
}


.c-navigation-item__arrow {
  display: none;
}

