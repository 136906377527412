.u-text-bold {
  font-weight: 600;
}

.u-form-readonly {
  .u-form-readonly__input-readonly {
    pointer-events: none;
    @apply paragraph-bold;
    color: theme('colors.grayscales.gray2');
  }
  .c-input-label {
    @apply paragraph-base;
    color: theme('colors.grayscales.gray1');
  }
}

.l-grid-wrapper {
  overflow-x: hidden;
  overflow-y: hidden;
}

.u-container-width {
  margin: 0 auto 0 0;
}

.u-position-btn-prim-sec {
  display: flex;
  flex-direction: column;

  @screen tablet {
    flex-direction: row;
    justify-content: flex-end;
  }

  .u-position-btn-prim {
    margin-bottom: $default-spacing-small;
    order: 1;

    button {
      margin-top: $default-spacing-small;
      margin-left: 0;
    }

    @screen tablet {
      margin-bottom: 0;
      order: 2;

      button {
        margin-top: 0;
        margin-left: $default-spacing-small;
      }
    }
  }
  .u-position-btn-sec {
    order: 2;

    @screen tablet {
      order: 1;
    }
  }
}

.hidden-desktop {
  @screen mobile {
    display: none;
  }
}
.hidden-mobile {
  @screen toMobile {
    display: none;
  }
}
.hidden-tablet {
  @screen toTablet {
    display: none;
  }
}

.u-seperator {
  display: flex;
  justify-content: space-between;
  > div {
    border: none;
    border-right: 0.1rem solid theme('colors.grayscales.gray4');
    text-align: center;
    padding: 0 0.8rem;
    width: 100%;
  }

  > div:last-child {
    border: none;
  }
}

.u-divider {
  width: auto;
  border-top: 0.1rem solid theme('colors.grayscales.gray4');
  display: block;
  margin: 3rem 0;
}

.nowrap {
  white-space: nowrap;
}

body.overlay-visible {
  .overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }
}

@mixin sticky-container() {
  position: sticky;
  text-align: center;
  bottom: 0;
  left: 0;
  margin-bottom: -6rem;
  width: 100%;
  background-color: theme('colors.grayscales.white');
  z-index: $z-index-200;
  -webkit-box-shadow: 0rem -0.4rem 1rem rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0rem -0.4rem 1rem rgba(0, 0, 0, 0.25);
  box-shadow: 0rem -0.4rem 1rem rgba(0, 0, 0, 0.25);
}

.u-sticky-container {
  @include sticky-container();
  padding: 1.3rem 1.6rem;
}

.u-sticky-container-mobile {
  @screen toTablet {
    // Sticky container at bottom of page on mobile
    @include sticky-container();
    padding: 1.6rem;
    bottom: 60px;
    position: fixed;
    width: 100vw;
  }
}
