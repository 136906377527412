// =============================================================================
// Reset list
// =============================================================================

/**
 * Info
 * -----
 * Reset a list by removing the padding and list-style-type when no styling is
 * applied to it yet.
 */

@mixin reset-list() {
  margin: 0;
  padding-left: 0;

  list-style-type: none;
}
