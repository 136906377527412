// =============================================================================
// Root
// =============================================================================
.c-header {
  display: flex;

  justify-content: space-between;
  align-content: center;

  flex-flow: row;

  height: $globals-header-height;

  border-bottom: 0.1rem solid theme('colors.grayscales.gray4');

  color: theme('colors.grayscales.gray1');
}

.c-icon {
  @apply fontawesome-base;
  @apply fontawesome-medium;
}

// =============================================================================
// Elements
// =============================================================================
.c-header__logo {
  position: relative;
  display: flex;
  align-items: center;

  height: inherit;

  cursor: pointer;
}

.c-header__logo-img {
  height: inherit;

  &:focus {
    outline: none;
  }

  // background-color: set-color("brand" "flemish-dark");
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 28px;
    height: 100%;
    background-color: #ffe615;
    transition: background 0.1s ease-in-out;
    border-bottom: 0.1rem solid theme('colors.grayscales.gray4');
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 28px;
    width: 0;
    height: 0;
    border-top: 4.3rem solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 1.35rem solid theme('colors.flemish.dark');
    transition: border-color 0.1s ease-in-out;
  }

  svg {
    position: relative;
    text-decoration: none;
    font-weight: 500;
    color: inherit;

    width: 3.1rem;
    height: 3.1rem;
    left: 0.3rem;
    top: 0.5rem;
    margin-right: 1.7rem;
    fill: #333332;
  }
}

.c-header__logo-label {
  @apply h5-base;

  position: relative;

  padding: 0 0.7rem;

  text-decoration: none;

  color: theme('colors.grayscales.gray1');

  &:hover {
    color: theme('colors.cta.base');
  }
}

ngx-ov-header-title-link {
  margin-left: 0.2rem;

  .c-header__title-link {
    @apply h5-base;

    position: relative;

    padding: 0 0.7rem;

    text-decoration: none;

    color: theme('colors.grayscales.gray1');

    &:hover {
      color: theme('colors.cta.base');
    }

    .c-header__title-link__label {
      position: relative;
      margin-right: 10px;
    }
  }
}

ngx-ov-header-title-link:not(:last-child) {
  .c-header__title-link {
    .c-header__title-link__label::after {
      content: '';
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
      height: 2.6rem;
      width: 1px;
      background-image: none !important;
      background-color: #cbd2da;
      transform: rotate(-20deg);
      transform-origin: top left;
      background-size: 16px 44px;
    }
  }
}

.c-header__rightside {
  position: relative;

  display: flex;
  align-items: center;

  .c-header__rightside__extra {
    display: flex;
    height: 100%;
  }
}

.c-header__contact {
  position: relative;
  height: 100%;

  display: flex;
  align-items: center;

  padding: 0 1rem 0 2rem;

  background-color: theme('colors.flemish.dark');

  cursor: pointer;

  &::after {
    content: '';

    position: absolute;

    bottom: 0;
    left: 0;

    display: block;

    border-bottom: 4rem solid theme('colors.grayscales.white');
    border-right: 1.4rem solid theme('colors.flemish.dark');
  }

  &:hover .c-header__contact-link,
  &:hover .c-icon {
    color: theme('colors.cta.base');
  }
}

.c-header__contact-link,
.c-header__profile-link {
  @apply h7-base;

  text-decoration: none;

  margin: 0 0.5rem;

  color: theme('colors.grayscales.gray1');
}

.c-header__profile {
  position: relative;
  height: 100%;

  display: flex;
  align-items: center;

  cursor: pointer;

  &:hover .c-header__profile-link,
  &:hover .c-icon {
    color: theme('colors.cta.base');
  }
}

// =============================================================================
// Modifiers
// =============================================================================
.c-header--ahovoks {
  .c-header__logo-img {
    &::before {
      background-color: theme('colors.grayscales.white');
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 2.8rem;
      top: 0;
      // height: 5.4rem;
      width: 1px;
      background-image: none !important;
      background-color: theme('colors.grayscales.gray4');
      transform: rotate(-20deg);
      transform-origin: top left;
      background-size: 1.6rem 4.44rem;
      border-left: none;
      // background-color: theme('colors.grayscales.gray5');
    }
  }

  .c-header__contact {
    background-color: theme('colors.primary.dark');

    color: theme('colors.grayscales.white');

    &::after {
      border-right-color: theme('colors.primary.dark');
    }
  }

  .c-header__contact-link {
    color: theme('colors.grayscales.white');
  }
}

// =============================================================================
// States
// =============================================================================
.c-header__title-link--hidden-mobile {
  @screen toTablet {
    display: none;
  }
}
