$flanders-font-path: '../../../fonts/flanders/';
$fa-font-path: '../../../fonts/fontawesome';
$globals-svg-path: 'projects/svg';

@import '/projects/ngx-ov-ui/src/core/scss/fonts';

@import '~flatpickr/dist/flatpickr.css';
@import '~flatpickr/dist/plugins/monthSelect/style.css';
@import 'ckeditor5';

@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.mat-horizontal-content-container {
  overflow: visible !important;
}

.translationCode {
  color: #fd5358;
  font-size: larger;
}

/* Workaround: formulier elementen outlines op reactive formulier */
.c-panel__content {
  overflow: visible;
}



