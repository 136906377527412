// =============================================================================
// Root
// =============================================================================
.c-doormat-acm {
  display: flex;
  flex-direction: row;
  padding: 2rem;
  background-color: theme('colors.grayscales.gray5');
  border: 0.1rem solid theme('colors.grayscales.gray4');

  text-decoration: none;
  color: inherit;

  cursor: default;
}

// =============================================================================
// Elements
// =============================================================================
.c-doormat-acm__arrow-title {
  display: flex;
  flex-direction: row;

  @apply h6-base;
  padding-bottom: 1rem;
}

.c-doormat-acm__arrow {
  @apply fontawesome-medium;
  padding-right: 1rem;
}

.c-doormat-acm__icon {
  @apply fontawesome-image;
  margin-bottom: auto;
}

.c-doormat-acm__action {
  text-align: right;
  width: 30%;
  display: flex;
  flex-direction: column;
}

.c-doormat-acm__content {
  width: 70%;
}

.c-doormat-acm__text {
  @apply paragraph-base;
}

.c-doormat-acm__footer {
  margin-top: auto;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-doormat-acm--with-link {
  &:hover {
    background-color: theme('colors.grayscales.gray6');

    cursor: pointer;

    .c-doormat-acm__title {
      text-decoration: underline;
    }
  }
}

// =============================================================================
// States
// =============================================================================
