.c-pager {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 0.1rem solid theme('colors.grayscales.gray4');
  width: 70rem;
  padding: 0.4rem;
  height: 4.7rem;
  align-items: center;
  margin-left: auto;
  margin-top: -0.1rem;
}

.c-pager__info {
  text-align: center;
}

.c-pager__navigation {
  a {
    cursor: pointer;
    padding: 0 0.4rem;
    i {
      padding: 0 1.2rem;
    }
  }
}

.c-pager__pagesize {
  padding-left: 1rem;
  text-align: left;
  vertical-align: middle;
  display: flex;
  flex-direction: row;

  select {
    border: none;
  }
}

.c-pager__nav--disabled {
  display: none;
  pointer-events: none;
}

.c-pager__navigation--current {
  @apply paragraph-bold;
}
