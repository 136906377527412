// =============================================================================
// Root
// =============================================================================

.c-required-mark {
  color: theme('colors.ui.red1');
  @apply paragraph-base;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
}
