// =============================================================================
// Root
// =============================================================================

.c-3-way-toggle--container {
  width: 11.6rem;
  height: 3.5rem;

  background-color: theme('colors.grayscales.gray5');
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

// =============================================================================
// Elements
// =============================================================================

.c-3-way-toggle__radio-neutral,
.c-3-way-toggle__radio-off,
.c-3-way-toggle__radio-on {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.c-3-way-toggle .c-3-way-toggle__label-neutral,
.c-3-way-toggle .c-3-way-toggle__label-off,
.c-3-way-toggle .c-3-way-toggle__label-on {
  @apply fontawesome-base;
  @apply fontawesome-medium;

  i {
    color: theme('colors.grayscales.gray3');
  }

  border-radius: 2rem;
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.c-3-way-toggle .c-3-way-toggle__label-off {
  margin-right: -0.3rem;
}

.c-3-way-toggle .c-3-way-toggle__label-on {
  margin-left: -0.3rem;
}

.c-3-way-toggle .c-3-way-toggle__label-neutral {
  // Font-size van icon xsmall was te klein
  font-size: 0.8rem;
  i {
    color: theme('colors.grayscales.gray4');
  }
}

// =============================================================================
// Modifiers
// =============================================================================

.c-3-way-toggle--light {
  background-color: theme('colors.grayscales.white');
}

// =============================================================================
// States
// =============================================================================

.c-3-way-toggle__radio-on:checked + .c-3-way-toggle__label-on {
  background-color: theme('colors.ui.green1');
  i {
    color: theme('colors.grayscales.white');
  }
}

.c-3-way-toggle__radio-off:checked + .c-3-way-toggle__label-off {
  background-color: theme('colors.ui.red1');
  i {
    color: theme('colors.grayscales.white');
  }
}

.c-3-way-toggle__radio-neutral:checked + .c-3-way-toggle__label-neutral {
  background-color: theme('colors.cta.base');
  i {
    color: theme('colors.grayscales.white');
  }
}
