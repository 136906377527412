.c-login-page-login-button {
  &__container {
    @apply h7-uppercase;

    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    place-content: center flex-start;
    align-items: center;

    margin: 0 1.3rem;
    height: 100%;

    cursor: pointer;

    @screen tablet {
      margin: 0 3.3rem;
    }
  }

  &__icon {
    margin-right: 0.9rem;
  }

  &__label {
    @screen toTablet {
      display: none;
    }
  }
}
