// =============================================================================
// Root
// =============================================================================
.c-pill {
  @apply paragraph-bold;

  display: inline-flex;

  height: 2.2rem;
  align-items: center;

  border-radius: 0.4rem;

  border: 0.07rem solid theme('colors.grayscales.gray4');

  background-color: theme('colors.grayscales.gray5');
  color: theme('colors.grayscales.gray2');
}

// =============================================================================
// Elements
// =============================================================================
.c-pill__text {
  text-align: center;
  padding: 0.3rem 0.5rem;
}

.c-pill__close {
  position: relative;
  @apply fontawesome-base;
  @apply fontawesome-small;

  padding: 0rem 0.5rem;
  border-left: 0.1rem solid theme('colors.grayscales.gray4');
  height: 2.2rem;

  color: theme('colors.cta.base');
}

.c-pill__close-touch {
  background-color: transparent;
  width: 3.4rem;
  height: 3.4rem;
  position: absolute;
  z-index: $z-index-base;
  top: -0.6rem;
  left: -0.6rem;
  cursor: pointer;
}

// =============================================================================
// Modifiers
// =============================================================================

// =============================================================================
// States
// =============================================================================
.c-pill {
  &--warning {
    background-color: theme('colors.ui.orange1');
    color: theme('colors.grayscales.white');
  }

  &--error {
    background-color: theme('colors.ui.red1');
    color: theme('colors.grayscales.white');
  }

  &--success {
    background-color: theme('colors.ui.green1');
    color: theme('colors.grayscales.white');
  }
}
