// =============================================================================
// Root
// =============================================================================
.c-contact-card {
  @apply paragraph-base;

  background-color: theme('colors.grayscales.white');
}

// =============================================================================
// Elements
// =============================================================================
.c-contact-card__item {
  display: flex;
  flex-direction: row;
}

.c-contact-card__label {
  width: 11.5rem;
  min-width: 11.5rem;

  @screen toTablet {
    display: none;
  }
}

.c-contact-card__value {
  display: flex;
  flex-direction: column;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-contact-card__value--highlight {
  @apply paragraph-bold;
}

// =============================================================================
// States
// =============================================================================
