.c-progress {
  z-index: $z-index-100;

  height: 0.6rem;
  width: 100%;

  &__indicator {
    height: 100%;
    background-color: theme('colors.cta.base');
  }
}
