// =============================================================================
// Root
// =============================================================================
.c-boolean-indicator {
  @apply fontawesome-base;
  // TODO: waarom 2?
  @apply fontawesome-medium;
  display: inline;
}

// =============================================================================
// Elements
// =============================================================================
$white: theme('colors.grayscales.white');

.c-boolean-indicator__icon {
  background-image: linear-gradient($white, $white);
  background-repeat: no-repeat;
  background-size: 66% 63%;
  background-position: center;
}

// =============================================================================
// Modifiers
// =============================================================================

.c-boolean-indicator--true:after {
  content: '\f058';
  color: theme('colors.ui.green1');
}

.c-boolean-indicator--false:after {
  content: '\f057';
  color: theme('colors.ui.red1');
}

// =============================================================================
// States
// =============================================================================
