.c-badge {
  @apply paragraph-bold;

  display: inline-block;

  min-width: 2.4rem;
  min-height: 2.4rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  border-radius: 1.2rem;

  text-align: center;
}

.c-badge__wrapper {
  position: relative;
  padding-top: 1.5rem;
  .c-badge {
    position: absolute;
    margin-left: -1rem;
    margin-top: -1rem;
    white-space: nowrap;
  }
}
