// =============================================================================
// Link - Desktop
// =============================================================================

a,
.ov-ui-a,
.ov-ui-a-small {
  @apply paragraph-base;
  color: theme('colors.cta.base');
  text-decoration: underline;
}

a:focus-visible {
  box-shadow: none;
  outline: 3px solid theme('colors.cta.outline');
  outline-offset: 2px;
}

.ov-ui-a-small {
  @apply small-base;
}
