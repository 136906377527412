// =============================================================================
// Root
// =============================================================================
.c-alert {
  @apply paragraph-base;

  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 1.6rem;

  background-color: theme('colors.grayscales.gray5');
  border: 0.1rem solid theme('colors.grayscales.gray5');

  text-align: left;

  cursor: text;

  @screen mobile {
    display: flex;
    flex-direction: row;

    padding: 2.4rem 1.6rem;
  }
}

// =============================================================================
// Elements
// =============================================================================
.c-alert__content {
  margin-top: 0.9rem;

  @screen mobile {
    margin-left: 3.6rem;
    margin-top: 0;
  }
}

.c-alert__icon {
  display: flex;
  justify-content: flex-start;
  padding-top: 0.4rem;

  @screen mobile {
    display: flex;
    justify-content: center;

    margin-left: 1.5rem;
    padding-top: 0.4rem;
  }

  .c-icon {
    @screen mobile {
      @apply fontawesome-xlarge;
      width: 3.7rem;
      height: 3.7rem;
    }

    @apply fontawesome-medium;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 2rem;
    height: 2rem;

    border-radius: 50%;

    background-color: theme('colors.grayscales.white');
  }

  .c-alert__title {
    margin-left: 1.5rem;
  }
}

.c-alert__title {
  @apply paragraph-bold;
  @screen mobile {
    @apply h4-base;
  }

  color: theme('colors.grayscales.gray1');
}

.c-alert__message {
  @apply paragraph-base;

  color: theme('colors.grayscales.gray1');
}

// =============================================================================
// Modifiers
// =============================================================================
.c-alert--error {
  background-color: theme('colors.ui.red3');

  .c-icon {
    color: theme('colors.ui.red1');
  }
}

.c-alert--warning {
  background-color: theme('colors.ui.orange3');

  .c-icon {
    color: theme('colors.ui.orange1');
  }
}

.c-alert--success {
  background-color: theme('colors.ui.green3');

  .c-icon {
    color: theme('colors.ui.green1');
  }
}

// =============================================================================
// States
// =============================================================================
