.c-progress-block {
  width: 100%;
  display: block;
  text-decoration: none;

  .c-progress-block__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: theme('colors.cta.base');

    .c-progress-block__title {
      @apply small-base;
      align-self: center;
    }

    .c-progress-block__icon {
      @apply fontawesome-medium;
      margin-top: 0.2rem;
    }
  }

  .c-progress-block__bar {
    margin-top: 0.8rem;

    .c-progress {
      border-radius: 10rem;
      background: theme('colors.grayscales.gray5');

      .c-progress__indicator {
        border-radius: 10rem;
      }
    }
  }

  &.c-progress-block--action:hover {
    cursor: pointer;

    &:not(.c-progress-block--completed) {
      .c-progress-block__header {
        color: theme('colors.cta.dark');
      }

      .c-progress__indicator {
        background-color: theme('colors.cta.dark');
      }
    }
  }
}

.c-progress-block.c-progress-block--completed {
  .c-progress-block__header {
    color: theme('colors.ui.green1');
  }

  .c-progress-block__bar .c-progress,
  .c-progress-block__bar .c-progress .c-progress__indicator {
    background-color: theme('colors.ui.green1');
  }
}

.c-progress-block.c-progress-block--disabled {
  cursor: default;
  .c-progress-block__header {
    color: theme('colors.grayscales.gray4');
  }

  .c-progress-block__bar .c-progress,
  .c-progress-block__bar .c-progress .c-progress__indicator {
    background-color: theme('colors.grayscales.gray5');
  }
}
