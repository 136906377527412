// =============================================================================
// Root
// =============================================================================

// =============================================================================
// Elements
// =============================================================================
.c-profile__box {
  display: flex;
  flex-direction: row;

  width: 100%;
  padding: 2rem;

  background-color: theme('colors.grayscales.gray6');
  border: 0.1rem solid theme('colors.grayscales.gray5');

  @screen tablet {
    padding: 3.2rem;
    width: 49.6rem;
    max-width: 100%;
  }
}

.c-profile__picture {
  position: relative;
  width: 10.85rem;
  height: 10.85rem;
}

.c-profile__picture__placeholder {
  color: theme('colors.grayscales.gray3');
}

.c-profile__picture__image {
  img {
    width: 10.85rem;
    height: 10.85rem;
    border-radius: 100%;
    object-fit: cover;
  }
}

.c-profile__picture__overlay {
  width: 100%;
  height: 100%;
  z-index: $z-index-base + 1;
  position: absolute;
  text-align: center;
  cursor: pointer;
  overflow: hidden;

  i {
    font-size: 2.5rem;
    position: absolute;
    right: 0;
    top: 0;
    background: theme('colors.grayscales.gray6');
    border-radius: 10rem;
    border: 0.2rem solid theme('colors.grayscales.gray6');
    color: theme('colors.cta.base');
  }

  div {
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.8rem;

    @apply h7-uppercase;
    color: theme('colors.cta.base');
  }

  &:hover {
    div {
      visibility: visible;
    }
  }
}

.c-profile__picture__overlay__text {
  height: 10.85rem;
}

.c-profile__details {
  flex: 1;
  display: flex;
  flex-direction: column;

  padding-left: 2rem;

  @screen tablet {
    padding-left: 3.2rem;
    flex-direction: row;
    justify-content: space-between;
  }
}

.c-profile__detail {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-profile__value {
  color: theme('colors.grayscales.gray2');
}

.c-profile__title {
  @apply paragraph-bold;
}

.c-profile__extra-info {
  padding-top: 0.4rem;
}

// =============================================================================
// Modifiers
// =============================================================================

// =============================================================================
// States
// =============================================================================

.c-profile__picture:hover {
  .c-profile__picture__overlay {
    i {
      color: theme('colors.cta.dark');
    }
  }

  .c-profile__picture__image img,
  .c-profile__picture__placeholder {
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=30)'; // IE8
    filter: alpha(opacity=30); // IE 5-7
    opacity: 0.3;
  }
}
