// =============================================================================
// Fonts loading
// =============================================================================
/**
 * Info
 * -----
 * Load the fonts with the get-font mixin, which loads in a font and their
 * different weights in different formats.
 */

$flanders-font-path: '/assets/fonts/flanders/' !default;

// =============================================================================
// Flanders Art Sans
// =============================================================================

$flanders-sans-font-name: 'Flanders Art Sans';
$flanders-sans-family: 'flanders-sans';

@font-face {
  font-family: $flanders-sans-font-name;
  src: url($flanders-font-path + $flanders-sans-family + '-light.eot');
  src: url($flanders-font-path + $flanders-sans-family + '-light.svg');
  src: url($flanders-font-path + $flanders-sans-family + '-light.ttf');
  src: url($flanders-font-path + $flanders-sans-family + '-light.woff');
  src: url($flanders-font-path + $flanders-sans-family + '-light.woff2');
  font-weight: $font-weight-light;
}

@font-face {
  font-family: $flanders-sans-font-name;
  src: url($flanders-font-path + $flanders-sans-family + '-regular.eot');
  src: url($flanders-font-path + $flanders-sans-family + '-regular.svg');
  src: url($flanders-font-path + $flanders-sans-family + '-regular.ttf');
  src: url($flanders-font-path + $flanders-sans-family + '-regular.woff');
  src: url($flanders-font-path + $flanders-sans-family + '-regular.woff2');
  font-weight: $font-weight-regular;
}

@font-face {
  font-family: $flanders-sans-font-name;
  src: url($flanders-font-path + $flanders-sans-family + '-medium.eot');
  src: url($flanders-font-path + $flanders-sans-family + '-medium.svg');
  src: url($flanders-font-path + $flanders-sans-family + '-medium.ttf');
  src: url($flanders-font-path + $flanders-sans-family + '-medium.woff');
  src: url($flanders-font-path + $flanders-sans-family + '-medium.woff2');
  font-weight: $font-weight-medium;
}

@font-face {
  font-family: $flanders-sans-font-name;
  src: url($flanders-font-path + $flanders-sans-family + '-bold.eot');
  src: url($flanders-font-path + $flanders-sans-family + '-bold.svg');
  src: url($flanders-font-path + $flanders-sans-family + '-bold.ttf');
  src: url($flanders-font-path + $flanders-sans-family + '-bold.woff');
  src: url($flanders-font-path + $flanders-sans-family + '-bold.woff2');
  font-weight: $font-weight-bold;
}

// =============================================================================
// Flanders Art Serif
// =============================================================================

$flanders-serif-font-name: 'Flanders Art Serif';
$flanders-serif-family: 'flanders-serif';

@font-face {
  font-family: $flanders-serif-font-name;
  src: url($flanders-font-path + $flanders-serif-family + '-light.eot');
  src: url($flanders-font-path + $flanders-serif-family + '-light.svg');
  src: url($flanders-font-path + $flanders-serif-family + '-light.ttf');
  src: url($flanders-font-path + $flanders-serif-family + '-light.woff');
  src: url($flanders-font-path + $flanders-serif-family + '-light.woff2');
  font-weight: $font-weight-light;
}

@font-face {
  font-family: $flanders-serif-font-name;
  src: url($flanders-font-path + $flanders-serif-family + '-regular.eot');
  src: url($flanders-font-path + $flanders-serif-family + '-regular.svg');
  src: url($flanders-font-path + $flanders-serif-family + '-regular.ttf');
  src: url($flanders-font-path + $flanders-serif-family + '-regular.woff');
  src: url($flanders-font-path + $flanders-serif-family + '-regular.woff2');
  font-weight: $font-weight-regular;
}

@font-face {
  font-family: $flanders-serif-font-name;
  src: url($flanders-font-path + $flanders-serif-family + '-medium.eot');
  src: url($flanders-font-path + $flanders-serif-family + '-medium.svg');
  src: url($flanders-font-path + $flanders-serif-family + '-medium.ttf');
  src: url($flanders-font-path + $flanders-serif-family + '-medium.woff');
  src: url($flanders-font-path + $flanders-serif-family + '-medium.woff2');
  font-weight: $font-weight-medium;
}

@font-face {
  font-family: $flanders-serif-font-name;
  src: url($flanders-font-path + $flanders-serif-family + '-bold.eot');
  src: url($flanders-font-path + $flanders-serif-family + '-bold.svg');
  src: url($flanders-font-path + $flanders-serif-family + '-bold.ttf');
  src: url($flanders-font-path + $flanders-serif-family + '-bold.woff');
  src: url($flanders-font-path + $flanders-serif-family + '-bold.woff2');
  font-weight: $font-weight-bold;
}
/*
@include get-font(
  $flanders-serif-font-name,
  $flanders-font-path + $flanders-serif-family + '-light',
  $font-weight-light,
  normal
);
@include get-font(
  $flanders-serif-font-name,
  $flanders-font-path + $flanders-serif-family + '-regular',
  $font-weight-regular,
  normal
);
@include get-font(
  $flanders-serif-font-name,
  $flanders-font-path + $flanders-serif-family + '-medium',
  $font-weight-medium,
  normal
);
@include get-font(
  $flanders-serif-font-name,
  $flanders-font-path + $flanders-serif-family + '-bold',
  $font-weight-bold,
  normal
);
*/
