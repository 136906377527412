.highlight-item {
  &__container {
    text-align: center;
    padding: 2.4rem 0 0 0;

    @screen tablet {
      padding: 6.8rem 1.6rem;
    }
  }

  &__cta {
    cursor: pointer;
  }

  &__title {
    margin-top: 0;

    @screen tablet {
      margin-top: 2.4rem;
    }
  }

  &__info {
    padding-bottom: 2.4rem;

    @screen tablet {
      padding-bottom: 0;
    }
  }

  &__icon-xl {
    font-size: 100px;
    color: theme('colors.primary.dark');
  }
}
