// =============================================================================
// Variables
// =============================================================================
$bullet1: 1rem;
$bullet2: 2rem;
$bullet3: 3rem;

// =============================================================================
// Root
// =============================================================================
.c-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

// =============================================================================
// Elements
// =============================================================================
.c-loader__icon {
  position: relative;

  display: inline-block;
  width: 2rem;
  height: 1rem;

  &::before {
    content: '';

    position: absolute;
    top: 50%;
    left: 50%;

    display: block;
    width: 0.4rem;
    height: 0.4rem;
    margin-top: -0.2rem;
    margin-left: -2.2rem;

    box-shadow: $bullet1 0 theme('colors.grayscales.gray3'),
      $bullet2 0 theme('colors.grayscales.gray3'),
      $bullet3 0 theme('colors.grayscales.gray3');
    border-radius: 50%;

    animation: loader-wave infinite 1s linear;
  }
}

.c-loader__text {
  margin-top: 1rem;
}

// =============================================================================
// Modifiers
// =============================================================================

.c-loader--oneline {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;

  .c-loader__text {
    padding-left: 1.5rem;
  }
}

// =============================================================================
// States
// =============================================================================

// =============================================================================
// Animations
// =============================================================================
@keyframes loader-wave {
  0% {
    box-shadow: $bullet1 0 theme('colors.grayscales.gray3'),
      $bullet2 0 theme('colors.grayscales.gray3'),
      $bullet3 0 theme('colors.grayscales.gray3');
  }

  10% {
    box-shadow: $bullet1 -0.3rem theme('colors.grayscales.gray3'),
      $bullet2 0 theme('colors.grayscales.gray3'),
      $bullet3 0 theme('colors.grayscales.gray3');
  }

  20% {
    box-shadow: $bullet1 -0.6rem theme('colors.grayscales.gray3'),
      $bullet2 -0.3rem theme('colors.grayscales.gray3'),
      $bullet3 0 theme('colors.grayscales.gray3');
  }

  30% {
    box-shadow: $bullet1 -0.3rem theme('colors.grayscales.gray3'),
      $bullet2 -0.6rem theme('colors.grayscales.gray3'),
      $bullet3 -0.3rem theme('colors.grayscales.gray3');
  }

  40% {
    box-shadow: $bullet1 0 theme('colors.grayscales.gray3'),
      $bullet2 -0.3rem theme('colors.grayscales.gray3'),
      $bullet3 -0.6rem theme('colors.grayscales.gray3');
  }

  50% {
    box-shadow: $bullet1 0 theme('colors.grayscales.gray3'),
      $bullet2 0 theme('colors.grayscales.gray3'),
      $bullet3 -0.3rem theme('colors.grayscales.gray3');
  }

  60% {
    box-shadow: $bullet1 0 theme('colors.grayscales.gray3'),
      $bullet2 0 theme('colors.grayscales.gray3'),
      $bullet3 0 theme('colors.grayscales.gray3');
  }

  100% {
    box-shadow: $bullet1 0 theme('colors.grayscales.gray3'),
      $bullet2 0 theme('colors.grayscales.gray3'),
      $bullet3 0 theme('colors.grayscales.gray3');
  }
}
