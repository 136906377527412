// =============================================================================
// Root
// =============================================================================
.c-control-panel {
  width: 100vw;
  height: 100vh;
  z-index: $z-index-500;
  position: fixed;
  right: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .c-select {
    z-index: $z-index-600;
  }

  @screen tablet {
    position: relative;
    width: auto;
    height: auto;
    z-index: auto;
    right: auto;
    top: auto;
  }
}

// =============================================================================
// Elements
// =============================================================================

.c-control-panel__header {
  @apply h1-base;

  width: 100%;

  position: sticky;
  top: 0;
  right: 0;
  padding: 1rem 2rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background: theme('colors.grayscales.gray5');
  border-bottom: 0.1rem solid theme('colors.cta.base');
  z-index: $z-index-100;

  @screen tablet {
    @apply h4-uppercase;
    padding: 0.8rem 0rem 0.8rem 0rem;
    position: relative;
    width: auto;
    height: auto;
    z-index: auto;
    right: auto;
    top: auto;

    background: inherit;
    border-bottom: 0.1rem solid theme('colors.grayscales.gray4');
  }

  .c-control-panel__close-icon {
    @apply fontawesome-medium;
    color: theme('colors.grayscales.gray2');
    align-self: center;

    @screen tablet {
      display: none;
    }
  }
}

.c-control-panel__filters {
  flex-basis: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: auto;
  background-color: theme('colors.grayscales.white');

  @screen tablet {
    padding-top: 0rem;
    margin-top: 2rem;
    height: auto;
    background-color: theme('colors.grayscales.gray5');
  }
}

.c-control-panel__footer {
  background: theme('colors.grayscales.gray6');
  padding: 2rem;
  width: 100%;
  position: sticky;
  bottom: 0;
  right: 0;
  box-shadow: 0px -4px 17px rgba(0, 0, 0, 0.25);

  @screen tablet {
    background: inherit;
    position: relative;
    width: auto;
    height: auto;
    z-index: auto;
    right: auto;
    top: auto;

    box-shadow: none;

    background-color: theme('colors.grayscales.gray5');
  }
}

// =============================================================================
// Modifiers
// =============================================================================

.c-control-panel-item__header--hidden {
  @screen tablet {
    display: none;
  }
}
// =============================================================================
// States
// =============================================================================
