.c-datepicker-inputs__input {
  @apply paragraph-base;

  padding: 1rem 1.3rem;

  width: inherit;

  height: 4.6rem;

  border: 0.1rem solid theme('colors.grayscales.gray4');
}
