// =============================================================================
// Typography
// =============================================================================

/**
 * Info
 * -----
 * This file contains all the settings concerning the base typography. These
 * should NOT be used to define the typography of a standalone component,
 * but should provide a reusable system.
 */

// =============================================================================
// Font families
// =============================================================================
$font-family-sans: ('Flanders Art Sans', 'Helvetica', 'Arial', sans-serif);
$font-family-serif: ('Flanders Art Serif', 'Helvetica', 'Arial', serif);
$font-family-secondary: ('Font Awesome', 'Helvetica', 'Arial', sans-serif);
$font-family-awesome-free: (
  'Font Awesome 6 Free',
  FontAwesome,
  Font Awesome,
  sans-serif
);

// =============================================================================
// Font weights
// =============================================================================

/**
 * Source
 * -----
 * https://bigelowandholmes.typepad.com/bigelow-holmes/2015/07/
 */

$font-weight-black: 900;
$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;

// =============================================================================
// Line heights constants
// =============================================================================
$line-height-default: 1.5;
$line-height-normalize: 1;

// =============================================================================
// Font styles
// =============================================================================

@import 'typography_tailwind';
