// =============================================================================
// Root
// =============================================================================

// =============================================================================
// Elements
// =============================================================================
.c-panel__title {
  @apply h4-uppercase;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;

  padding: 0.8rem 0rem 0.8rem 0rem;
  align-items: center;
}

.c-panel__title-right {
  @apply paragraph-base;
}

.c-panel__sub-title {
  @apply paragraph-base;
  width: 100%;
  padding: 0.4rem 0.8rem 0.8rem 0rem;
  display: block;

  @screen tablet {
    display: inline;
    padding-left: 2rem;
  }
}

.c-panel__content {
  padding: 0.8rem 0rem 0.8rem 0rem;
  overflow-x: visible;
}

@screen tablet {
  .c-panel__title {
    border-bottom: 0.1rem solid theme('colors.grayscales.gray4');
  }

  .c-panel__content {
    padding: 1.8rem 0rem 0.8rem 0rem;
  }
}

// =============================================================================
// Modifiers
// =============================================================================

// =============================================================================
// States
// =============================================================================
