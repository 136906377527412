// =============================================================================
// Base
// =============================================================================

/**
 * Info
 * -----
 * File that contains all the styling used to normalize or create a base for the
 * start of the project or the start of a component. This file should be
 * imported once in storybook to make sure every component has a correct base
 * to start with.
 */

@charset "utf-8";

// =============================================================================
// Normalize
// =============================================================================

/**
 * Info
 * -----
 * Third party styling used to correctly setup a base to start the slicing.
 */

@import '~normalize.css/normalize.css';

// =============================================================================
// Tailwind
// =============================================================================

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'settings/typography';

// =============================================================================
// Globals
// =============================================================================

/**
 * Info
 * -----
 * Contains the global variables, mixins and functions that gives the base
 * the ability to make use of its functionality.
 */

@import 'globals';

// =============================================================================
// Utils
// =============================================================================

/**
 * Info
 * -----
 * Utility classes are used to apply one type of styling to the element.
 * These properties must have !important defined, because these styles should
 * never get overwritten.
 */

// =============================================================================
// Layouts
// =============================================================================

/**
 * Info
 * -----
 * BEM-based classes used to give structure to pages. DO NOT use this in
 * components. Layouts can be combined or be different in structure and are
 * therefore simple and do not have a fixed DOM.
 */

// =============================================================================
// Base styling components
// =============================================================================

/**
 * Info
 * -----
 * Files that create a solid basis for the tags, elements and components
 * used in the application. Everything start here! No classes are anything but
 * tags allowed!
 */

@import 'base/_html', 'base/_body', 'base/_heading', 'base/_paragraph',
  'base/_link', 'base/_button', 'base/_img', 'base/_label', 'base/_ul',
  'base/_ol', 'base/_hr', 'base/_blockquote', 'base/_section', 'base/_figure';

// =============================================================================
// Fonts
// =============================================================================

// =============================================================================
// Components
// =============================================================================

@import 'components/index';
@import 'utils/utils/utils';

@import 'tools/extensions/material';
@import 'layouts/login-page.component';
@import 'layouts/login-page-login-button.component';

@import 'tailwind.scss';
