.c-404 {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.c-404__content {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-self: center;

  .c-404__title {
    @apply h2-base;
    padding-bottom: 1rem;
  }

  .c-404__subtitle {
    @apply paragraph-base;
  }

  .c-404__hyperlinks {
    padding: 2rem 5rem;
  }

  .c-404__hyperlink-item {
    @apply paragraph-base;
    display: block;

    a {
      cursor: pointer;
    }

    &:before {
      @apply fontawesome-base;
      @apply fontawesome-small;
      content: '\f054';
      color: theme('colors.grayscales.gray1');
      padding-right: 1rem;
    }
  }
}

.c-404__image {
  width: 100%;
  height: 16.9rem;
  align-self: center;
  order: -1;
  background-image: url($globals-svg-path + '404.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@screen mobile {
  .c-404 {
    flex-direction: row;
  }

  .c-404__image {
    width: 33.8rem;
    height: 31.2rem;
    order: 0;
  }
}
