// =============================================================================
// Z-index
// =============================================================================
/**
 * Info
 * -----
 * The z-indexes are contextually based on the different layers within a site.
 * We try to keep these manageable. If a custom z-index is applied it is based
 * upon one of the items by adding (+) or subtracting (-) a number.
 * A more visual overview is shown in Figma
 */

/**
 * Used by
 * -------
 * None
 */
$z-index-top: 9999;

/**
 * Used by
 * -------
 * Flyout (-1)
 */
$z-index-600: 600;

/**
 * Used by
 * -------
 * ControlPanel: voor mobiele weergave
 * Cookiebar: container
 * Modal + de overlay
 * Navigation: mobiel menu
 */
$z-index-500: 500;

/**
 * Used by
 * -------
 * Cookiebar: overlay
 * Navigation: mobiel menu overlay
 * Search: mobiel filter menu op resultaten
 * Snackbar (+50)
 */
$z-index-400: 400;

/**
 * Used by
 * -------
 * Select: mobiel
 * Button: floating action button
 * FAB Survey
 */
$z-index-300: 300;

/**
 * Used by
 * -------
 * Navigation: mobiel menu uitklap menu header.
 * Utils: Sticky container
 */
$z-index-200: 200;

/**
 * Used by
 * -------
 * Tooltip
 * ProgressBar (+1)
 */
$z-index-100: 100;

/**
 * Used by
 * -------
 * Pill
 * Navigation: mobiel menu toggles onderaan.
 * Profile: picture overlay (+1)
 * Checkbox (+1)
 * Radio (+1)
 */
$z-index-base: 10;

/**
 * Used by
 * -------
 */
$z-index-background: -1;
