// =============================================================================
// Root
// =============================================================================

.c-input-label {
  @apply paragraph-bold;
  color: theme('colors.grayscales.gray2');
  padding-bottom: 0.8rem;
  display: block;

  &--readonly {
    padding-bottom: 0rem;
  }

  .c-input-label__text {
    padding-right: 0.4rem;
  }

  .c-input-label__required-mark {
    padding-right: 0.4rem;
  }
}
// compact layout override
.compact-layout .c-input-label {
  padding-bottom: 0;
}

.u-form-hide-required {
  .c-required-mark {
    display: none;
  }
}

.u-form-show-optional {
  .c-input-label__marker_optional::after {
    // OAM-794: vertaalbaar maken
    content: ' (optioneel)';
    color: theme('colors.grayscales.gray3');
    padding-right: 0.4rem;
    @apply small-base;
  }
}
