$width: 2.2rem;
$height: 2.2rem;

// =============================================================================
// Root
// =============================================================================
.c-radio {
  @apply paragraph-base;

  padding-left: 1.2rem;
  width: fit-content;

  label {
    cursor: inherit;
  }
}

// =============================================================================
// Elements
// =============================================================================
.c-radio__toggle {
  position: relative;
  top: 0.3rem;
  background-color: theme('colors.grayscales.white');

  width: 1.6rem;
  min-width: 1.6rem;
  height: 1.6rem;

  margin-right: 1.2rem;

  appearance: none;
  background-clip: content-box;
  border: 0.1rem solid theme('colors.grayscales.gray4');
  outline: 3px solid transparent;

  border-radius: 50%;

  transition: all 0.3s cubic-bezier(1, 0.1, 0, 0.9) 0.05s;

  &:focus {
    box-shadow: none;
    outline-color: theme('colors.cta.outline');
    outline-offset: 2px;
  }
}

.c-radio__toggle:checked {
  border: 0.5rem solid theme('colors.cta.base');
  background-clip: content-box;
}

.c-radio__container {
  display: inline-flex;
  margin-bottom: 0.4rem;
}

.c-radio__error-message {
  display: block;

  color: theme('colors.ui.red1');
}

.c-radio__content {
  margin-left: 2.8rem;
}

.c-radio__label-container {
  margin-left: 0;
}

.c-radio__label {
  display: flex;
}

// alternatieve representatie checkbox vink

.c-radio__toggle_box {
  position: relative;
  display: none;

  flex: 0 0 $width;
  align-self: start;

  width: $width;
  height: $height;
  margin: 0.3rem 1.2rem 0.3rem 1.2rem;
  line-height: 1;

  &::before {
    @apply fontawesome-base;
    @apply fontawesome-xsmall;

    content: '\f00c';

    position: absolute;
    top: 1.1rem;
    left: 1rem;
    z-index: $z-index-base + 1;

    display: block;

    line-height: 1;
    text-align: center;

    color: theme('colors.grayscales.white');

    transition: all 0.3s cubic-bezier(1, 0.1, 0, 0.9) 0.1s;
    transform: translateZ(0) translate(-50%, -50%) scale(0);
  }

  &::after {
    content: '';

    z-index: $z-index-base;

    display: inline-block;
    width: $width;
    height: $height;
    overflow: hidden;

    background: theme('colors.grayscales.white');
    border: 0.05rem solid theme('colors.grayscales.gray4');
    border-radius: 50%;
    outline: 0.05rem solid transparent;

    white-space: nowrap;

    transition: all 0.3s cubic-bezier(1, 0.1, 0, 0.9);

    cursor: pointer;
  }
}

// =============================================================================
// Modifiers
// =============================================================================

// =============================================================================
// States
// =============================================================================
.c-radio {
  &.is-error {
    .c-radio__toggle {
      border: 0.1rem solid theme('colors.ui.red1');
    }

    .c-radio__toggle:checked {
      border: 0.5rem solid theme('colors.cta.base');
    }
  }

  &.is-error-group {
    .c-radio__toggle {
      border: 0.1rem solid theme('colors.ui.red1');
    }

    .c-radio__toggle:checked {
      border: 0.5rem solid theme('colors.cta.base');
    }
  }

  &.is-disabled {
    .c-radio__toggle {
      background-color: theme('colors.grayscales.gray4');
      cursor: not-allowed;
    }

    .c-radio__toggle:checked {
      border: 0.5rem solid theme('colors.grayscales.gray3');
      background-clip: content-box;
    }
  }

  &.show-block {
    display: block;
    width: 100%;
    background-color: theme('colors.grayscales.gray5');
    padding: 1.6rem 0;
    margin: 0.1rem 0;

    .c-radio__container,
    .c-radio__error-message {
      margin-left: 1.6rem;
      margin-bottom: 0rem;
    }
    .c-radio__content {
      margin-left: 4.4rem;
      margin-top: 0.4rem;
    }
  }
}
