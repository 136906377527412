.c-wizard {
  ngx-ov-radio-group {
    display: flex;
    flex-direction: column;

    ngx-ov-radio:not(:last-child) {
      margin-bottom: 1.6rem;
    }
    ngx-ov-radio {
      min-height: 100%;
      cursor: pointer;

      .c-radio__toggle_box {
        align-self: center;
        margin: 0.3rem 1.6rem;
      }
      .c-radio__toggle,
      .c-radio__toggle_box {
        display: none;
      }
      .c-radio--selected,
      & :hover {
        box-shadow: 0px 0px 0px 2px theme('colors.cta.base') inset;
      }
      .c-radio--selected {
        .c-radio__toggle_box {
          display: inline;
        }
      }
    }
  }

  .c-radio {
    @apply h5-base;
    text-align: left;
    color: theme('colors.grayscales.gray2');
    border-radius: 0.4rem;
    position: relative;

    background-color: theme('colors.grayscales.gray5');
    width: auto;
    min-height: 100%;
    display: block;
    padding: 1.2rem;
    & :hover {
      box-shadow: none;
    }

    .c-checkbox__label {
      line-height: 3rem;
      margin-bottom: 0;
    }
  }

  .c-radio__container {
    display: flex;
    margin-bottom: 0;
    line-height: 3rem;

    .c-radio__toggle {
      order: 3;
      line-height: 3rem;
    }
    .c-radio__toggle_box {
      order: 4;
      line-height: 3rem;

      &::before {
        font-size: 1.2rem;
      }
    }

    .c-radio__icon {
      order: 1;
      padding-right: 1.6rem;
      line-height: 3rem;
      display: flex;
      align-items: center;

      i {
        font-size: 2.4rem !important;
        @apply fontawesome-medium;
      }
    }

    .c-radio__label-container {
      order: 2;
    }
  }
}
