// =============================================================================
// Root
// =============================================================================
.c-tooltip {
  display: inline-block;
  // position: relative;
}

// =============================================================================
// Elements
// =============================================================================

.c-tooltip__wrapper {
  position: relative;
}

.c-tooltip__icon {
  @apply fontawesome-base;
  @apply fontawesome-small;

  color: theme('colors.cta.base');
  @screen toMobile {
    cursor: pointer;
  }
}

.c-tooltip__content {
  @apply small-base;
  width: 28.4rem;

  position: absolute;
  z-index: $z-index-100;
  top: -0.1rem;

  padding: 1.2rem;
  background-color: theme('colors.grayscales.white');

  //TODO: schaduws moeten in vars gestoken worden
  box-shadow: 0px 10px 15px -3px rgba(26, 32, 44, 0.1),
    0px 4px 6px -2px rgba(26, 32, 44, 0.05);
  border: 0.1rem solid theme('colors.grayscales.gray5');
}

.c-tooltip__arrow,
.c-tooltip__arrow_overlay {
  position: relative;
  z-index: $z-index-100 + 1;
  top: 0.5rem;
  &:after,
  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
  }

  &:after {
    border: 0.8rem solid transparent;
    border-bottom-color: theme('colors.grayscales.white');
  }

  &:before {
    border: 0.9rem solid transparent;
    border-bottom-color: theme('colors.grayscales.gray5');
  }
}
.c-tooltip__arrow_overlay {
  z-index: $z-index-100 + 1;
}

.tippy-box[data-placement^='top'] > .tippy-arrow::before {
  border-top-color: theme('colors.grayscales.gray5');
}
.tippy-box[data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: theme('colors.grayscales.gray5');
}
.tippy-box[data-placement^='left'] > .tippy-arrow::before {
  border-left-color: theme('colors.grayscales.gray5');
}
.tippy-box[data-placement^='right'] > .tippy-arrow::before {
  border-right-color: theme('colors.grayscales.gray5');
}

.tippy-content {
  background-color: theme('colors.grayscales.white');
  box-shadow: 0px 10px 15px -3px rgba(26, 32, 44, 0.1),
    0px 4px 6px -2px rgba(26, 32, 44, 0.05);
  border: 0.1rem solid theme('colors.grayscales.gray5');

  color: theme('colors.grayscales.gray1');
}

.c-tooltip__label {
  color: theme('colors.cta.base');
  text-decoration: underline;
  cursor: pointer;
}

// =============================================================================
// Modifiers
// =============================================================================

.c-tooltip--small {
  .c-tooltip__content {
    width: initial;
    max-width: 10.8rem;
    padding: 0.4rem 0.8rem;
  }
}

.c-tooltip--right {
  .c-tooltip__content {
    left: -0.8rem;
  }
}

.c-tooltip--left {
  .c-tooltip__content {
    right: -2.8rem;
  }
}
