// =============================================================================
// Root
// =============================================================================
.c-doormat {
  display: flex;

  background-color: theme('colors.grayscales.gray5');

  text-decoration: none;
  color: inherit;

  cursor: default;

  &:hover {
    background-color: theme('colors.grayscales.gray6');
    cursor: pointer;
    .c-doormat__title {
      text-decoration: none;
    }
  }
}

// =============================================================================
// Elements
// =============================================================================
.c-doormat__arrow-content {
  padding: 1.6rem 0.5rem;

  background: theme('colors.cta.base');
  color: theme('colors.grayscales.white');
}

.c-doormat__arrow {
  @apply fontawesome-xsmall;
}

.c-doormat__content-container {
  width: 100%;
  padding: 1.6rem;
}

.c-doormat__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.c-doormat__content-right-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.c-doormat__icon {
  padding: 0.8rem 0 0.8rem 0.8rem;
}

.c-doormat__more-info-link {
  color: theme('colors.cta.base');
  cursor: pointer;
  width: 100%;
  text-align: right;
  a {
    @apply small-base;
  }
  i {
    @apply fontawesome-small;
  }
}

.c-doormat__title {
  @apply h5-base;

  text-decoration: underline;
  color: theme('colors.cta.base');
}

.c-doormat__text p {
  @apply paragraph-base;
  margin-top: 0.4rem;

  min-height: fit-content;
  max-height: 100%;

  color: theme('colors.grayscales.gray1');
}

.c-doormat__button {
  margin-top: 1rem;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-doormat__arrow-content--warning {
  background: theme('colors.ui.orange2');
}

// =============================================================================
// States
// =============================================================================
