// =============================================================================
// Root
// =============================================================================
// .c-info-block {
// }

// =============================================================================
// Elements
// =============================================================================
.c-info-block__header {
  position: relative;

  display: flex;
  align-items: center;

  width: 100%;

  &:hover {
    cursor: pointer;

    .c-info-block__collapsable {
      color: theme('colors.grayscales.gray3');
    }
  }
}

.c-info-block__title {
  flex-shrink: 0;

  margin: 0 0.8rem;

  @screen tablet {
    margin: 0 1.6rem 0 1.2rem;
  }
}

.c-info-block__icon {
  position: relative;

  display: flex;
  align-items: center;
  padding: 1rem;

  font-size: 1.3rem;

  background-color: theme('colors.flemish.dark');

  &::after {
    content: '';

    position: absolute;

    bottom: 0;
    right: -0.8rem;

    display: block;

    border-bottom: 3rem solid theme('colors.flemish.dark');
    border-right: 0.8rem solid transparent;
  }

  @screen tablet {
    margin-right: 1rem;
    padding: 1rem;

    font-size: 2rem;

    &::after {
      right: -1.4rem;

      border-bottom: 4rem solid theme('colors.flemish.dark');
      border-right: 1.4rem solid transparent;
    }
  }
}

.c-info-block__collapsable {
  display: flex;
  align-items: center;
  margin-right: 0.8rem;

  color: theme('colors.grayscales.gray4');

  @screen tablet {
    margin-right: 1.6rem;
  }
}

.c-info-block__content {
  padding: 2.6rem 0 4rem 0;
}

.c-info-block__decorator {
  width: 100%;
  height: 0.3rem;

  background-color: theme('colors.grayscales.gray5');
}
