// =============================================================================
// Globals
// =============================================================================

/**
 * Info
 * -----
 * File that contains all the settings, functions, mixins and variables used to
 * create a consistent system in every component or layout. This file should be
 * imported in every component to make sure they can make use of these variables
 * and instances. This file should NOT contain any classes or styling to avoid
 * duplication.
 */

@charset "utf-8";

// =============================================================================
// Tools - mixins
// =============================================================================

/**
 * Info
 * -----
 * Mixins, similar to functions, are used to make our lives in the wonderful
 * world of SCSS enjoyable. These can either contain logic, but can also
 * manipulate styling.
 */

@import 'tools/mixins/_list', 'tools/mixins/_typography';

// =============================================================================
// Settings
// =============================================================================

/**
 * Info
 * ----
 * Variables and maps used to define the base like colors, typographic
 * font-styles, breakpoints, ...
 */

@import 'settings/typography/_index', 'settings/_fonts', 'settings/_utils',
  'settings/_z-index', 'settings/_globals';

@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';
