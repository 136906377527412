// =============================================================================
// Root
// =============================================================================

.c-textarea {
  display: flex;
  flex-direction: column;
}

// =============================================================================
// Elements
// =============================================================================

.c-textarea__input {
  @apply paragraph-base;
  display: inline-block;

  max-width: 100%;
  height: auto;

  padding: 0.5rem 1rem;

  border-radius: 0;
  border: 0.1rem solid theme('colors.grayscales.gray4');

  color: theme('colors.grayscales.gray1');
  background: theme('colors.grayscales.white');

  &:focus-visible {
    box-shadow: none;
    outline: 3px solid theme('colors.cta.outline');
    outline-offset: 2px;
  }
}

.c-textarea__info {
  display: flex;
}

.c-textarea__counter {
  @apply small-base;

  padding-top: 0.3rem;
  text-align: right;
  color: theme('colors.grayscales.gray3');
}

// =============================================================================
// Modifiers
// =============================================================================

// =============================================================================
// States
// =============================================================================
.c-textarea__input {
  &.is-error {
    border: 0.1rem solid theme('colors.ui.red1');

    background-color: theme('colors.ui.red3');

    &:focus {
      border: 0.1rem solid theme('colors.ui.orange1');

      background-color: theme('colors.grayscales.white');
    }
  }

  &.is-disabled {
    background-color: theme('colors.grayscales.gray5');

    pointer-events: none;
  }
}

.c-textarea__input-readonly {
  pointer-events: none;

  @apply paragraph-bold;

  color: theme('colors.grayscales.gray2');
}
