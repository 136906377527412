.c-login-page {
  &__intro {
    &-container {
      @screen tablet {
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        place-content: center space-around;
        align-items: center;
      }

      img {
        max-height: 30rem;
      }
    }

    &-subtitle {
      color: theme('colors.grayscales.gray3');
    }
  }
}

.c-login-page__intro-container__with-image {
  text-align: center;
  @screen tablet {
    text-align: left;
  }
}
