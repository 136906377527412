// =============================================================================
// Root
// =============================================================================
.c-main-layout {
  @apply paragraph-base;
}

// =============================================================================
// Elements
// =============================================================================
.c-main-layout__header {
  background-color: theme('colors.grayscales.white');

  width: 100vw;
  height: $globals-header-height;
}

.c-main-layout__wrapper-horizontal {
  background-color: theme('colors.grayscales.white');

  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;

  display: flex;
  flex-direction: row;
}

.c-main-layout__wrapper-content {
  flex-grow: 1;
  overflow: scroll;

  display: flex;
  flex-direction: column;

  .c-main-layout__wrapper-content__content {
    flex-grow: 1;
    padding-bottom: 6rem;
    position: initial;
  }
}

// =============================================================================
// Modifiers
// =============================================================================
.c-main-layout--with-header {
  .c-main-layout__wrapper-horizontal-without{
    position: fixed;
    height: calc(100vh - #{$globals-header-height} );
    top: $globals-header-height;
    @screen tablet{
      height: calc(100vh - #{$globals-header-height} - 4.95rem);
      top: calc(4.95rem + #{$globals-header-height});
    }
  }
  .c-main-layout__wrapper-horizontal-with{
    position: fixed;
    height: calc(100vh - #{$globals-header-height} );
    top: $globals-header-height;
  }
}

// =============================================================================
// States
// =============================================================================
