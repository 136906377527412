// =============================================================================
// Root
// =============================================================================

.c-toggle {
  position: relative;
  width: 3.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

// =============================================================================
// Elements
// =============================================================================

.c-toggle__checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.c-toggle__label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4rem;
}

.c-toggle__inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.c-toggle__inner:before,
.c-toggle__inner:after {
  @apply small-bold;

  display: block;
  float: left;
  width: 50%;
  height: 1.7rem;
  color: theme('colors.grayscales.white');
}

.c-toggle__inner:before {
  content: 'I';
  line-height: 1.7rem;
  padding-left: 0.5rem;
  background-color: #0055cc;
  color: #ffffff;
}

.c-toggle__inner:after {
  content: 'O';
  line-height: 1.7rem;
  padding-right: 0.3rem;
  background-color: theme('colors.grayscales.gray5');
  color: theme('colors.grayscales.white');
  text-align: right;
}

.c-toggle__switch {
  display: block;
  width: 2.1rem;
  height: 2.1rem;
  margin: -0.2rem;
  background: theme('colors.grayscales.white');
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1.8rem;
  border: 0.1rem solid theme('colors.grayscales.gray4');
  border-radius: 2rem;
  transition: all 0.3s ease-in 0s;
  &:focus-visible {
    box-shadow: none;
    outline: 3px solid theme('colors.cta.outline');
    outline-offset: 2px;
  }
}

// =============================================================================
// Modifiers
// =============================================================================

.c-toggle--large {
  width: 6.5rem;

  .c-toggle__inner:before {
    padding-left: 1rem;
    line-height: 3.5rem;
  }

  .c-toggle__inner:after {
    padding-right: 1rem;
    line-height: 3.5rem;
  }

  .c-toggle__inner:before,
  .c-toggle__inner:after {
    height: 3.5rem;
  }

  .c-toggle__switch {
    height: 3.9rem;
    width: 3.9rem;
    border: 0.2rem solid theme('colors.grayscales.gray4');
    right: 3.3rem;
    margin: -0.2rem;
  }
}

// =============================================================================
// States
// =============================================================================

.c-toggle__checkbox:checked + .c-toggle__label .c-toggle__inner {
  margin-left: 0;
}

.c-toggle__checkbox:checked + .c-toggle__label .c-toggle__switch {
  right: 0;
}
