// =============================================================================
// Root
// =============================================================================
.c-form-message {
  @apply paragraph-base;
}

// =============================================================================
// Elements
// =============================================================================

// =============================================================================
// Modifiers
// =============================================================================

// =============================================================================
// States
// =============================================================================
.c-form-message {
  &.is-annotation {
    color: theme('colors.grayscales.gray3');
  }

  &.is-error {
    color: theme('colors.ui.red1');
  }

  &.is-success {
    color: theme('colors.ui.green1');

    &::after {
      @apply fontawesome-base;
      content: '\f00c';
    }
  }
}
