// =============================================================================
// Root
// =============================================================================
.c-data-table {
  width: 100%;
  text-align: left;
}

// =============================================================================
// Elements
// =============================================================================

.c-data-table__container {
  width: 100%;
  border-collapse: collapse;
}

.c-data-table__cell {
  padding: 0.4rem;
  height: 2.7rem;
  vertical-align: middle;
  white-space: nowrap;

  .c-data-table__filter-field {
    border: 1px theme('colors.grayscales.gray4') solid;
  }
}

.c-data-table__cell.break_word {
  white-space: normal;
  word-break: break-all;
}

.c-data-table__header {
  border-bottom: 0.3rem solid theme('colors.grayscales.gray4');
  .c-data-table__cell {
    @apply h6-base;

    &:hover {
      background-color: theme('colors.grayscales.gray5');
    }
  }

  .sorting:after,
  .sorting_asc:after,
  .sorting_desc:after,
  .sorting_asc_disabled:after,
  .sorting_desc_disabled:after {
    content: '';
    @apply fontawesome-base;
    @apply fontawesome-small;
    color: theme('colors.grayscales.gray2');
    float: right;
    width: 1rem;
  }

  .sorting:hover:after {
    content: '\f0dc';
  }

  .sorting_asc:after {
    content: '\f0de';
  }

  .sorting_desc:after {
    content: '\f0dd';
  }

  .sorting_asc_disabled:after {
    content: '\f0de';
  }

  .sorting_desc_disabled:after {
    content: '\f0dd';
  }
}

.c-data-table__body .c-data-table__row {
  @apply paragraph-base;
  border-bottom: 0.1rem solid theme('colors.grayscales.gray4');

  &:nth-child(odd) {
    background: theme('colors.grayscales.white');
  }

  .c-data-table__icon {
    @apply fontawesome-small;
    color: theme('colors.grayscales.gray2');
  }
}
.c-data-table__detail_row {
  width: inherit;
  background: theme('colors.grayscales.gray6');
}

.c-data-table__cell__select {
  max-width: 1rem;
}

.c-data-table-settings {
  position: relative;
  padding: 0 0.8rem;
  &__overlay {
    height: -webkit-fill-available;
    height: 100vh;
    width: 100vw;
    width: -webkit-fill-available;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-index-600 -1;
    opacity: 0.3;
    background-color: theme('colors.grayscales.gray1');

    @screen mobile {
      display: none;
    }
  }
}

.c-data-table-settings__content {
  position: absolute;
  right: -1rem;

  width: fit-content;
  min-width: 20rem;
  margin: 2rem 0;
  padding: 2rem;

  background: theme('colors.grayscales.white');

  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);

  text-align: left;
  white-space: normal;

  z-index: $z-index-600;

  th {
    font-weight: 700;
  }

  .c-data-table-settings__label {
    text-wrap: nowrap;
  }
}

.c-data-table-settings__action_separator:before {
  content: ' | ';
  padding: 0 1rem;

  color: theme('colors.grayscales.gray4');
}


.compact-layout .c-data-table__cell input.c-datepicker__container {
  padding: 0.1rem 0.1rem 0.1rem 0.5rem;
  min-width: 160px;
  font-size: 1.3rem;
  word-break: break-word;
}
