// =============================================================================
// Root
// =============================================================================
.c-input-field {
  display: flex;

  align-items: stretch;
  flex-flow: column;
  width: 100%;

  input:focus-visible {
    box-shadow: none;
    outline: 3px solid theme('colors.cta.outline');
    outline-offset: 2px;
  }
}

// =============================================================================
// Elements
// =============================================================================
.c-input-field__label {
  margin-right: 0.5rem;
}

.c-input-field_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.1rem solid theme('colors.grayscales.gray4');

  .c-input-field_container_prefix {
    margin-left: 0.75rem;
    color: theme('colors.grayscales.gray3');
    user-select: none;
  }

  .c-input-field_container_suffix {
    margin-right: 0.75rem;
    color: theme('colors.grayscales.gray3');
    user-select: none;
  }

  .c-input-field__input {
    border: none !important;
    outline: none !important;
  }

  .c-input-field__input:focus-visible {
    box-shadow: none;
    outline: none !important;
    outline-offset: 0 !important;
  }
}

.c-input-field_container:focus-visible {
  box-shadow: none;
  outline: 3px solid theme('colors.cta.outline');
  outline-offset: 2px;
}

.c-input-field.is-error .c-input-field_container {
  border: 0.1rem solid #d2373c !important;
  background-color: #fec5c7 !important;
}

.c-input-field__input {
  @apply paragraph-base;
  padding: 1rem 1.3rem;
  width: 100%;
  height: 4.6rem;
  border: 0.1rem solid theme('colors.grayscales.gray4');
}
// compact layout override
.compact-layout .c-input-field__input {
  height: 2.6rem;
}

.c-input-field__icon-password-alignment {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1rem 1.3rem;
  height: 4.6rem;
  width: 5rem;
  border: 0.1rem solid theme('colors.grayscales.gray4');
  border-left: 0;
}

.c-input-field__icon-password {
  color: theme('colors.cta.base');
}

.c-input-field__extra-info {
  margin-top: 0.4rem;
}

.c-input-field__input-readonly {
  word-break: break-word;
}

// =============================================================================
// Modifiers
// =============================================================================

// =============================================================================
// States
// =============================================================================
.c-input-field {
  &.is-error {
    .c-input-field__input {
      border: 0.1rem solid theme('colors.ui.red1');

      background-color: theme('colors.ui.red3');

      &:hover {
        border-width: 0.1rem;
      }
    }
  }

  &.is-disabled {
    pointer-events: none;

    .c-input-field__input {
      background-color: theme('colors.grayscales.gray5');
    }
  }
}
