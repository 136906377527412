// =============================================================================
// Root
// =============================================================================

// =============================================================================
// Elements
// =============================================================================
.c-tabs__navigation {
  display: flex;
  padding: 0;
  width: 100%;

  border-bottom: 0.2rem solid theme('colors.grayscales.gray4');

  list-style: none;

  .c-tabs__navigation-item:not(:first-child) {
    .c-tabs__navigation-link {
      &::before {
        content: '';

        position: absolute;
        top: calc(50% - (1.3rem / 2));
        bottom: 0;
        left: 0;

        width: 0.05rem;
        height: 1.3rem;

        background: theme('colors.grayscales.gray4');
      }
    }
  }
}

.c-tabs__navigation-link {
  @apply paragraph-bold;
  color: theme('colors.cta.base');

  position: relative;

  display: block;
  margin-bottom: -0.2rem;
  padding: 0.4rem 1.2rem;

  border-bottom: 0.2rem solid transparent;

  transition: border-bottom 0.2s;

  &:hover {
    border-bottom: 0.2rem solid theme('colors.cta.base');
    cursor: pointer;
  }
  &.is-active {
    border-bottom: 0.2rem solid theme('colors.grayscales.gray1');
  }
}

.c-tabs__content {
  padding: 2rem 0.75rem;
}

.c-tabs__navigation-item:focus-visible{
  box-shadow: none;
  outline: 3px solid theme('colors.cta.outline');
  outline-offset: 2px;
}

