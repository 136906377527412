// =============================================================================
// Root
// =============================================================================

.c-select.open-search.searchable {
  @screen toTablet {
    position: absolute;
    top: 0;
    left: 0;
    background: theme('colors.grayscales.white');
    padding: 2rem;
    .ng-select {
      background: theme('colors.grayscales.white');
      height: 100vh;
      width: calc(100vw - 4rem);
      z-index: $z-index-300;

      .ng-dropdown-panel {
        width: calc(100%);
        border: none;
        margin-top: 0;

        .ng-dropdown-panel-items {
          max-height: calc(100vh - 25rem) !important;
        }
      }
    }

    .c-select__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .c-select__close_search {
      display: block;
      text-align: right;
    }
  }
}

.c-select__close_search {
  display: none;
}

// compact layout override
.compact-layout .ng-select .ng-select-container {
  min-height: 2.6rem;
  font-weight: 300;
}
.compact-layout .c-data-table__cell {
  font-weight: 300;
}


.ng-select {
  background-color: theme('colors.grayscales.white');
  text-align: left;

  &.ng-select-opened {
    > .ng-select-container {
      .ng-arrow-wrapper ::after {
        @apply fontawesome-base;
        @apply fontawesome-xsmall;
        content: '\f077';
      }
    }
  }
  &.ng-select-focused {
    &:not(.ng-select-opened) > .ng-select-container {
      border-color: theme('colors.ui.orange1');
    }
  }
  &.ng-select-disabled {
    > .ng-select-container {
      background-color: theme('colors.grayscales.gray5');
    }
  }
  .ng-has-value .ng-placeholder {
    display: none;
  }
  .ng-select-container {
    color: theme('colors.grayscales.gray1');
    border: 0.1rem solid theme('colors.grayscales.gray4');
    min-height: 4.6rem;
    align-items: center;
    .ng-value-container {
      align-items: center;
      padding-left: 1.3rem;
      .ng-placeholder {
        color: theme('colors.grayscales.gray3');
      }
    }
  }


  &.ng-select-single {
    .ng-select-container {
      height: 2rem;
      .ng-value-container {
        .ng-input {
          padding-left: 1rem;
        }
      }
    }

    .ng-clear-wrapper {
      color: theme('colors.grayscales.gray1');
      padding: 0.8rem 0.8rem;
      @screen toTablet {
        padding: 0.8rem 1.8rem;
      }
    }
  }
  &.ng-select-multiple {
    .ng-clear-wrapper {
      display: none;
    }
    &.ng-select-disabled {
      > .ng-select-container .ng-value-container .ng-value {
        background-color: theme('colors.grayscales.gray5');
      }
    }
    .ng-select-container {
      .ng-value-container {
        .ng-value {
          display: flex;
          flex-direction: row-reverse;
          padding-right: 0.5rem;
        }
      }
    }
  }

  .ng-arrow-wrapper {
    width: 2.5rem;
    padding-right: 1rem;
    ::after {
      @apply fontawesome-base;
      @apply fontawesome-xsmall;

      content: '\f078';
    }
  }
}

.ng-dropdown-panel {
  background: theme('colors.grayscales.white');
  border: 0.1rem solid theme('colors.grayscales.gray4');
  margin-top: -0.1rem;
  .ng-dropdown-panel-items {
    max-height: 25rem !important;

    .ng-optgroup {
      user-select: none;
      padding: 0.8rem 1.2rem;
      cursor: pointer;
      color: theme('colors.grayscales.gray3');
      &.ng-option-disabled {
        cursor: default;
      }
      &.ng-option-marked {
        background-color: theme('colors.grayscales.gray5');
      }
      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        @apply paragraph-bold;
        background-color: theme('colors.grayscales.gray5');
      }
    }
    .ng-option {
      padding: 1rem;
      white-space: inherit;
      overflow: inherit;
      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        @apply paragraph-bold;
        background-color: theme('colors.grayscales.gray5');
      }
      &.ng-option-marked {
        background-color: theme('colors.grayscales.gray5');
      }
      &.ng-option-child {
        padding-left: 22px;
      }
      &.ng-option-disabled {
        color: theme('colors.grayscales.gray4');
      }
      .ng-tag-label {
        font-size: 80%;
        font-weight: 400;
        padding-right: 5px;
      }
    }
  }
}

.ng-dropdown-panel-items > div > .ng-option > .ng-option-fixed {
  margin: -10px;
  padding: 10px;
  color: theme('colors.grayscales.gray2');
}
.ng-dropdown-panel-items > div > .ng-option > .ng-option-fixed.ng-option-first {
  border-top: 1px solid theme('colors.grayscales.gray4');
}

.highlight-search {
  font-weight: bolder;
}

.ng-select.ng-select.ng-select-single.ng-select-filtered:not(.ng-select-opened)
  .ng-select-container.ng-has-value
  .ng-value-container
  .ng-value {
  visibility: visible;
}

// =============================================================================
// Root
// =============================================================================

.ng-select-focused {
  box-shadow: none;
  outline: 3px solid theme('colors.cta.outline');
  outline-offset: 2px;
}

.c-select__input-readonly {
  pointer-events: none;

  @apply paragraph-bold;

  color: theme('colors.grayscales.gray2');
}

.is-error .ng-select .ng-select-container {
  border: 0.1rem solid theme('colors.ui.red1');
  background: theme('colors.ui.red3');
}


.ng-dropdown-panel-items > div > .ng-option:has(.c-select__fixed-item) {
  background-color: theme('colors.grayscales.gray6');
}

.ng-dropdown-panel-items > div > .ng-option:has(.c-select__fixed-first-item) {
  border-top: 1px solid theme('colors.grayscales.gray4');
}


// =============================================================================
// Overschrijven default ng-select
// =============================================================================

// Voor multiselect moeten de geselecteerde opties er uit zien zoals onze pills.
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: theme('colors.grayscales.gray5');
  color: theme('colors.grayscales.gray2');

  @apply paragraph-bold;

  display: inline-flex;

  height: 2.2rem;
  align-items: center;

  border-radius: 0.4rem;

  border: 0.07rem solid theme('colors.grayscales.gray4');
  margin: 0 0.4rem 0.2rem 0;
  padding-right: 0;

  .ng-value-label {
    text-align: center;
    padding: 0.3rem 0.5rem;
  }
  .ng-value-icon {
    position: relative;
    @apply fontawesome-base;
    @apply fontawesome-small;

    padding: 0rem 0.5rem;
    border-left: 0.1rem solid theme('colors.grayscales.gray4');
    height: 2.2rem;

    color: theme('colors.cta.base');
  }
}
