.c-cookiebar {
  z-index: $z-index-500;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid theme('colors.grayscales.gray5');
  background-color: theme('colors.grayscales.white');

  max-height: 90vh;

  &__overlay {
    height: -webkit-fill-available;
    height: 100vh;
    width: 100vw;
    width: -webkit-fill-available;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-index-400;
    opacity: 0.3;
    background-color: theme('colors.grayscales.gray1');
  }
}

.c-cookiebar__language {
  @apply h7-uppercase;
  color: theme('colors.grayscales.gray1');
  display: flex;
  padding-bottom: 1.6rem;

  @screen tablet {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    padding-bottom: 0;
  }

  div {
    padding-right: 0.8rem;
    cursor: pointer;
  }
}

.c-cookiebar__container {
  z-index: $z-index-500;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid theme('colors.grayscales.gray4');
  background-color: theme('colors.grayscales.white');

  max-height: 90vh;
  overflow: scroll;

  padding: 2.8rem 0;
  @screen tablet {
    padding: 3.6rem 0rem;
  }
}

.c-cookiebar__title {
  @apply h5-base;
  margin-bottom: 1.6rem;
}

.c-cookiebar__text {
  margin-bottom: 1.6rem;

  @screen tablet {
    width: fit-content;
  }
}

.c-cookiebar__action {
  margin-bottom: 3.2rem;

  button {
    margin-bottom: 1.2rem;
  }

  @screen tablet {
    button {
      margin-bottom: 0;
      margin-right: 1.6rem;
    }
  }
}

.c-cookiebar__language--active {
  color: theme('colors.grayscales.gray3');
}

.c-cookiebar__cookiesvoorkeuren {
  @apply paragraph-bold;
  color: theme('colors.cta.base');
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;

  i {
    @apply fontawesome-base;
    @apply fontawesome-small;
    margin-right: 0.8rem;
  }
}

.c-cookiebar__collapsed-content {
  margin: 0.8rem 2rem;
  border-top: 0.1rem solid theme('colors.grayscales.gray4');

  .c-cookiebar__checkboxes {
    margin: 1.6rem 0;
  }

  .c-cookiebar__extra-info {
    @apply small-base;
    color: theme('colors.grayscales.gray3');
    margin-bottom: 1.6rem;
    margin-left: 4rem;
  }
}

@screen mobile {
  .c-cookiebar {
    max-height: 70rem;
  }
  .c-cookiebar__container {
    max-height: 70rem;
  }
}
