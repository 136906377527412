// =============================================================================
// Root
// =============================================================================
.c-footer {
  @apply small-base;

  width: 100%;

  display: flex;
  padding: 3rem 1rem 8rem 1rem;

  flex-flow: column;
  text-align: left;

  border-top: 0.1rem solid theme('colors.grayscales.gray4');

  @screen tablet {
    flex-flow: row;
    padding: 3rem 1rem;
  }
}

.c-icon {
  @apply fontawesome-base;

  opacity: 0.5;
}

// =============================================================================
// Elements
// =============================================================================
.c-footer__site-info {
  margin-left: 0;
  & div > p:first-child {
    padding-bottom: 1rem;
  }

  flex: 100;
  @screen tablet {
    margin-left: 6rem;
    & div > p:first-child {
      padding-bottom: 0;
    }
  }
}

.c-footer__logo-double {
  display: flex;
  justify-content: space-between;
  flex-flow: row;
  gap: 1.6rem;

  @screen tablet {
    flex-flow: column;
    justify-content: center;
  }
}

.c-footer__logo {
  padding-bottom: 2rem;

  display: flex;
  justify-content: flex-start;
  flex-flow: row;

  height: fit-content;

  @screen tablet {
    padding-bottom: 0;
  }
}

.c-footer__logo-text {
  display: flex;
  align-items: flex-start;
  flex-flow: column;

  margin-left: 1rem;
}

.c-footer__logo-img::before {
  content: '';

  position: absolute;

  top: -0.7rem;
  left: 3rem;

  display: block;

  width: 0.1rem;
  height: 6.5rem;

  background-color: theme('colors.grayscales.gray4');

  transform: rotate(-20deg);
  transform-origin: top left;
}

.c-footer__logo-img {
  height: 4.4rem;
  width: 4rem;

  position: relative;

  background-image: url($globals-svg-path + 'flanders-logo.svg');
  background-repeat: no-repeat;
}

.c-footer__logo-second-img::before {
  position: absolute;

  top: -0.7rem;
  left: 3rem;

  display: block;

  width: 0.1rem;
  height: 6.5rem;
}

.c-footer__logo-second-img {
  max-height: 4.4rem;
}

.c-footer__contact {
  display: none;
}

.c-footer__contact-text {
  margin-right: 0.5rem;
}

.c-footer__site-info__publisher-link {
  color: theme('colors.cta.base');
}

.c-footer__logo-tagline {
  @apply small-base;
  padding-left: 0.2rem;
}

.c-footer__logo-title {
  @apply h5-base;
}

.c-footer__site-info__title {
  @apply paragraph-bold;
}

.c-footer__site-info__publisher {
  @apply small-base;
}

.c-footer__links {
  width: 100%;

  display: flex;
  flex-direction: column;

  .c-footer__links-basic {
    flex: 100;
  }
}

.c-footer__list,
.c-footer__list-languages {
  @apply h7-base;

  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  padding: 1rem 0 0;

  list-style-type: none;
}

.c-footer__list {
  li + li:before {
    content: ' | ';
    padding: 0 1rem;

    color: theme('colors.grayscales.gray4');
  }
}

.c-footer__versions {
  width: 100%;
}

.c-footer__list-item {
  @apply h7-base;

  padding: 0 0.5rem 0 0;

  text-decoration: none;

  color: theme('colors.grayscales.gray1');
  display: inline;

  cursor: pointer;

  &:hover {
    color: theme('colors.cta.base');
  }
}

// =============================================================================
// Modifiers
// =============================================================================
.c-footer__list-item--version {
  @apply small-versions;
  color: theme('colors.grayscales.gray3');
}

.c-footer__list-item--link {
  cursor: pointer;
}

// =============================================================================
// States
// =============================================================================

.c-footer__list-item--active {
  color: theme('colors.grayscales.gray3');
}
