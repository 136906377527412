// =============================================================================
// Section
// =============================================================================
.l-section,
.l-section--secondary {
  margin: 2rem 0;
  @screen tablet {
    margin: 4rem 0;
  }
}
// =============================================================================
// Elements
// =============================================================================

// Deze classes niet meer gebruiken, spacing van headings gebruiken (gedefinieerd in heading.scss) => Classes mogen niet weg want worden wel nog gebruikt in STL

.l-section__header {
  margin-bottom: 1.5rem;
  @screen tablet {
    margin-bottom: 3rem;
  }
}

// =============================================================================
// Modifiers
// =============================================================================
.l-section--dark {
  background-color: theme('colors.grayscales.gray5');
}

.l-section--underlined-gray {
  border-bottom: 0.1rem solid theme('colors.grayscales.gray4');
}

.l-section--underlined-white {
  border-bottom: 0.1rem solid theme('colors.grayscales.white');
}
