// =============================================================================
// Root
// =============================================================================
.c-news-teaser-list {
  position: relative;
  width: 100% !important;
}

.c-news-teaser {
  border-left: 0.1rem solid theme('colors.grayscales.gray4');
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

// =============================================================================
// Elements List
// =============================================================================

.c-news-teaser-list__wrapper {
  overflow: hidden;
  width: 100%;
}

.c-news-teaser-list__scroller {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: margin-left 0.5s;
  transition-timing-function: ease-in-out;

  @screen tablet {
    width: 100% !important;
    // prevent swipe to have effect on desktop version (on mobile swipe sets margin-left)
    margin-left: 0 !important;
  }
}

.c-news-teaser-list__controls {
  @screen tablet {
    display: none;
  }
}
.c-news-teaser-list__dots {
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}
.c-news-teaser-list__dot {
  height: 1.4rem;
  width: 1.4rem;
  background-color: theme('colors.grayscales.gray3');
  border-radius: 50%;
  display: inline-block;
  margin: 0.5rem;
}

.c-news-teaser-list__item {
  width: 28.15rem;
}

// =============================================================================
// Elements item
// =============================================================================

.c-news-teaser__image {
  height: 14.75rem;
  width: 28.15rem;
  object-fit: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  vertical-align: middle;
  position: relative;
}

.c-news-teaser__link {
  cursor: pointer;
}

.c-news-teaser__content {
  padding: 1rem;
}

.c-news-teaser__title {
  a {
    color: theme('colors.cta.base');
    @apply paragraph-bold;
    text-decoration: none;
  }
}

.c-news-teaser__label {
  @apply h7-uppercase;
  margin-bottom: 1rem;
}

.c-news-teaser__image-control {
  width: 5rem;
  height: 3rem;
  background-color: theme('colors.cta.base');
  color: theme('colors.grayscales.white');
  position: absolute;
  bottom: 0;
  left: 0;
  line-height: 3rem;
}

// =============================================================================
// Modifiers
// =============================================================================

// =============================================================================
// States
// =============================================================================
.c-news-teaser-list__dot-active {
  background-color: theme('colors.grayscales.gray2');
}
