// =============================================================================
// Button - (no classes)
// =============================================================================
button {
  @apply h6-base;

  // Disable default padding on button
  padding: 0.5rem 2rem;

  outline: none;

  border: none;

  cursor: pointer;
  /**
	 * Slicing applied when there is no class applied. For example: rendered in
	 * a WYSIWYG.
	 */
  // &:not([class]) {}

  // =============================================================================
  // States
  // =============================================================================
  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
}

button:focus-visible {
  box-shadow: none;
  outline: 3px solid theme('colors.cta.outline');
  outline-offset: 2px;
}
