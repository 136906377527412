// =============================================================================
// Label - (no classes)
// =============================================================================

/**
 * Info
 * -----
 * Contains slicing for the label tag.
 *
 * IMPORTANT TO NOTE!!!
 * -----
 * Do not specify any padding or margin on these tags to avoid having to
 * override them in every component.
 */

// =============================================================================
// Label
// =============================================================================
label {
  /**
	 * Slicing applied when there is no class applied. For example: rendered in
	 * a WYSIWYG.
	 */
  // &:not([class]) {}
}
