// =============================================================================
// Root
// =============================================================================
.c-search {
  @screen tablet {
    display: flex;
  }
}

// =============================================================================
// Elements
// =============================================================================

.c-search__results-container {
  width: 100%;
  min-height: calc(100vh - #{$globals-header-height});

  @screen tablet {
    flex: 70;
  }

  .c-search__results-container__filter-buttons {
    width: 70vw;
    z-index: $z-index-400;
    position: fixed;
    bottom: 2rem;
    display: block;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
  }
}

.c-search__filter-container {
  display: none;

  @screen tablet {
    display: inline;
    flex: 30;
  }
}

.c-search__results-container__filter-top {
  @screen tablet {
    display: none;
  }
}

.c-search__results-filter-container {
  display: none;
  background-color: theme('colors.grayscales.gray5');
  padding: 1rem;
  margin-bottom: 1rem;

  @screen tablet {
    display: block;
  }

  .c-control-panel-item {
    padding: 0;
  }
}

.c-search__extra-filter-container {
  @screen tablet {
    display: none;
  }
}

.c-search__info-container {
  margin-top: 2rem;
}

// =============================================================================
// Modifiers
// =============================================================================

.c-search__filter-container--visible {
  display: block;
}

// =============================================================================
// States
// =============================================================================

@screen tablet {
  .c-search__results-container .c-search__results-container__filter-buttons {
    display: none;
  }
}
