// =============================================================================
// Root
// =============================================================================
.c-spotlight {
  max-width: 17rem;

  border-top: 0.2rem solid theme('colors.cta.base');
}

.c-spotlight__title {
  @apply h5-base;

  margin-top: 0.8rem;

  div {
    text-decoration: none;

    color: theme('colors.cta.base');

    &:hover {
      text-decoration: underline;
    }
  }
}

// =============================================================================
// Elements
// =============================================================================

// =============================================================================
// Modifiers
// =============================================================================
.c-spotlight--background {
  background-color: theme('colors.grayscales.gray5');

  .c-spotlight__content {
    padding: 0 0.5rem 0.5rem;
  }
}

// =============================================================================
// States
// =============================================================================
