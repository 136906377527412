// =============================================================================
// Root
// =============================================================================

.c-arrow-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 0 2rem;
  background-color: theme('colors.grayscales.gray6');
  font-size: 1.6rem;
  line-height: 2.4rem;
}

// =============================================================================
// Elements
// =============================================================================

.c-arrow-navigation__next,
.c-arrow-navigation__previous {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  text-align: center;
  background-color: theme('colors.grayscales.gray5');

  &--invisible {
    visibility: hidden;
  }
}

.c-arrow-navigation__title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: theme('colors.grayscales.gray4');
}

.c-arrow-navigation__title-text {
  width: 100%;
  text-align: center;
}

.c-arrow-navigation__previous-placeholder,
.c-arrow-navigation__next-placeholder {
  display: none;

  cursor: pointer;
  color: theme('colors.grayscales.gray3');
}

.c-arrow-navigation__previous-placeholder {
  padding-left: 0.9rem;
  text-align: left;
  width: 15rem;
}

.c-arrow-navigation__next-placeholder {
  padding-right: 0.9rem;
  text-align: right;
  width: 15rem;
}

.c-arrow-navigation__previous-placeholder-spacer,
.c-arrow-navigation__next-placeholder-spacer {
  display: none;
  visibility: hidden;
  width: 10rem;
}

@screen mobile {
  .c-arrow-navigation__title-text {
    width: inherit;
  }

  .c-arrow-navigation__previous-placeholder,
  .c-arrow-navigation__next-placeholder {
    display: block;
  }

  .c-arrow-navigation__previous-placeholder-spacer,
  .c-arrow-navigation__next-placeholder-spacer {
    display: block;
  }
}

// =============================================================================
// Modifiers
// =============================================================================

// =============================================================================
// States
// =============================================================================
