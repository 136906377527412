/* stylelint-disable  selector-max-class */
/* stylelint-disable  selector-max-combinators */
/* stylelint-disable  selector-max-specificity */

/* Opgelet: er is een aparte scss voor de wizard versie van de checkbox: wizard/checkbox.scss */

// =============================================================================
// Variables
// =============================================================================
$width: 1.6rem;
$height: 1.6rem;

// =============================================================================
// Root
// =============================================================================
.c-checkbox {
  @apply paragraph-base;

  position: relative;

  width: fit-content;
  display: block;

  label {
    cursor: inherit;
  }
}

// =============================================================================
// Elements
// =============================================================================

.c-checkbox__container {
  display: inline-flex;
  flex-direction: row;
}

.c-checkbox__toggle_input {
  position: absolute;

  top: 0;
  left: 0;

  width: 0.1rem;
  height: 0.1rem;
  overflow: hidden;
  margin: -0.1rem;
  padding: 0;

  border: 0;
  clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem);
}

.c-checkbox__label {
  margin-bottom: 0.4rem;

  .c-tooltip {
    padding-left: 0.4rem;
  }
}

.c-checkbox__content {
  padding-left: 4rem;
}

.c-checkbox__toggle_box {
  position: relative;
  display: block;

  flex: 0 0 $width;
  align-self: start;

  width: $width;
  height: $height;
  margin: 0.3rem 1.2rem 0.3rem 1.2rem;
  line-height: 1;

  &::before {
    @apply fontawesome-base;
    @apply fontawesome-xsmall;

    content: '\f00c';

    position: absolute;
    top: 0.8rem;
    left: 0.7rem;
    z-index: $z-index-base + 1;

    display: block;

    line-height: 1;
    text-align: center;

    color: theme('colors.grayscales.white');

    transform: translateZ(0) translate(-50%, -50%) scale(0);

    transition: all 0.3s cubic-bezier(1, 0.1, 0, 0.9) 0.1s;
  }

  &::after {
    content: '';

    z-index: $z-index-base;

    display: inline-block;
    width: $width;
    height: $height;
    overflow: hidden;

    background: theme('colors.grayscales.white');
    border: 0.05rem solid theme('colors.grayscales.gray4');

    white-space: nowrap;

    transition: all 0.3s cubic-bezier(1, 0.1, 0, 0.9);

    cursor: pointer;
  }
}

.c-checkbox__toggle_input:checked + .c-checkbox__toggle_box::after {
  background: theme('colors.cta.base');
  color: theme('colors.cta.base');
  border: 0.05rem solid theme('colors.cta.base');
}

.c-checkbox__toggle_input:checked + .c-checkbox__toggle_box::before {
  transform: translateZ(0) translate(-50%, -50%) scale(1);
}

.c-checkbox__toggle_input:focus-visible + .c-checkbox__toggle_box::after {
  box-shadow: none;
  outline: 3px solid theme('colors.cta.outline');
  outline-offset: 2px;
}

.c-checkbox__extra-info {
  padding-left: 4rem;

  @screen tablet {
    padding-left: 0.4rem;
  }
}

// =============================================================================
// Modifiers
// =============================================================================

// =============================================================================
// States
// =============================================================================
.c-checkbox.is-error {
  .c-checkbox__toggle_input + .c-checkbox__toggle_box::before {
    color: theme('colors.ui.red1');
  }

  .c-checkbox__toggle_input + .c-checkbox__toggle_box::after {
    border-color: theme('colors.ui.red1');
  }

  .c-checkbox__toggle_input:checked + .c-checkbox__toggle_box::after {
    background: theme('colors.grayscales.white');
    color: theme('colors.ui.red1');
  }
}

.c-checkbox.is-disabled {
  .c-checkbox__toggle_input + .c-checkbox__toggle_box::after {
    background-color: theme('colors.grayscales.gray5');
    border: 0.1rem solid theme('colors.grayscales.gray4');
    cursor: default;
  }

  .c-checkbox__toggle_box {
    &::before {
      color: theme('colors.grayscales.gray2');
    }
  }
}

.c-checkbox.show-block {
  display: block;
  width: 100%;
  background-color: theme('colors.grayscales.gray5');
  padding: 1.6rem 0.4rem;
  margin: 0.1rem 0;

  .c-checkbox__label {
    margin-bottom: 0;
  }

  .c-checkbox__content {
    margin-top: 0.4rem;
  }
}

.c-checkbox.is-error-group {
  .c-checkbox__toggle_input + .c-checkbox__toggle_box::before {
    background-color: theme('colors.grayscales.white');
    color: theme('colors.ui.red1');
  }

  .c-checkbox__toggle_input + .c-checkbox__toggle_box::after {
    border-color: theme('colors.ui.red1');
  }

  .c-checkbox__toggle_input:checked + .c-checkbox__toggle_box::after {
    background: theme('colors.grayscales.white');
    color: theme('colors.ui.red1');
  }
}

.c-checkbox__icon-readonly {
  display: flex;
  align-items: center;

  .c-checkbox__icon-check-readonly {
    color: theme('colors.cta.base');
    margin-right: 1.3rem;
  }

  .c-checkbox__icon-xmark-readonly {
    color: theme('colors.grayscales.gray2');
    margin-right: 1.3rem;
  }
}
