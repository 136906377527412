// =============================================================================
// Globals
// =============================================================================

/**
 * Info
 * -----
 * Contains any variable that should be globally defined and is not linked to
 * a certain specific setting. Prefix these with $globals-[variable-name].
 */

// =============================================================================
// Definitions
// =============================================================================
// $globals-[variable-name]: ;

$globals-header-height: 4.4rem;

$globals-svg-path: '../../../svg/' !default;
