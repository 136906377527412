// =============================================================================
// Root
// =============================================================================
.c-global-alert {
  display: flex;
  justify-content: flex-start;

  background-color: theme('colors.grayscales.gray5');

  padding: 1.1rem;
  text-align: left;
  border-bottom: 0.1rem solid theme('colors.grayscales.gray4');
}

// =============================================================================
// Elements
// =============================================================================

$white: theme('colors.grayscales.white');

.c-global-alert__icon {
  display: flex;

  color: theme('colors.grayscales.gray2');

  span {
    background-image: linear-gradient($white, $white);
    background-repeat: no-repeat;
    background-size: 66% 63%;
    background-position: center;
  }

  .c-icon {
    @apply fontawesome-base;
    @apply fontawesome-medium;

    display: flex;

    width: 2rem;
    height: 2rem;

    border-radius: 50%;

    background-color: theme('colors.grayscales.white');
  }
}

.c-global-alert__message {
  @apply small-base;
  padding-left: 1rem;
  align-self: center;
  color: theme('colors.grayscales.gray1');
}

.c-global-alert__hyperlink {
  @apply small-base;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-global-alert--error {
  background-color: theme('colors.ui.red3');

  .c-icon {
    color: theme('colors.ui.red1');
  }
}

.c-global-alert--warning {
  background-color: theme('colors.ui.orange3');

  .c-icon {
    color: theme('colors.ui.orange1');
  }
}

.c-global-alert--success {
  background-color: theme('colors.ui.green3');

  .c-icon {
    color: theme('colors.ui.green1');
  }
}

// =============================================================================
// States
// =============================================================================
@screen mobile {
  .c-global-alert {
    padding: 0.8rem 1.6rem;
    text-align: center;
    justify-content: center;
  }

  .c-global-alert .c-icon {
    width: 2.3rem;
    height: 2.3rem;
  }
}
