// =============================================================================
// Root
// =============================================================================
.c-expansion-panel-item {
  width: 100%;
  border-bottom: 0.1rem solid theme('colors.grayscales.gray4');

  @screen tablet {
    border: 0.1rem solid theme('colors.grayscales.gray4');
    margin-bottom: 1.6rem;
  }
}

// =============================================================================
// Elements
// =============================================================================
.c-expansion-panel-item__intro {
  padding: 1.3rem 0;
  cursor: pointer;

  .c-expansion-panel-item__intro__chevron {
    float: left;
    margin: 0 2.7rem;
    width: 0.8rem;
    color: theme('colors.grayscales.gray3');
    font-size: 1.4rem;
  }

  .c-expansion-panel-item__intro__title {
    display: inline-block;
    @apply h4-base;
    color: theme('colors.cta.base');
    word-break: break-word;
  }

  .c-expansion-panel-item__intro__right-content {
    @apply h6-base;
    padding-top: 0.4rem;
    @screen tablet {
      text-align: right;
    }
  }
}

.c-expansion-panel-item__subtitle {
  @apply h6-base;
  color: theme('colors.grayscales.gray3');
  padding-top: 0.4rem;
  flex-basis: 100%;
}

.c-expansion-panel-item__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 2.7rem;
  @screen tablet {
    flex-direction: row;
  }
}

.c-expansion-panel-item__close {
  text-align: center;
  padding-top: 1.6rem;
  cursor: pointer;
  i {
    color: theme('colors.grayscales.gray3');
    padding-left: 1.2rem;
  }
}

.c-expansion-panel-item__right {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  flex-direction: column;
  @screen tablet {
    flex-direction: row;
  }
}

.c-expansion-panel-item__detail-container {
  background-color: theme('colors.grayscales.gray6');
  padding: 1.9rem 2.7rem;
  white-space: pre-line;
  word-break: break-word;
  @screen tablet {
    background-color: theme('colors.grayscales.white');
    border-top: 0.1rem solid theme('colors.grayscales.gray4');
  }
}

.c-expansion-panel-item__footer {
  background-color: theme('colors.grayscales.gray6');
  padding: 0.8rem;
  min-height: 4.8rem;
  @screen tablet {
    border-top: 0.1rem solid theme('colors.grayscales.gray4');
  }
}

.c-expansion-panel-item__seperator {
  display: none;
  @screen tablet {
    display: block;
    margin: 0 1rem;
    border-left: 0.1rem solid theme('colors.grayscales.gray4');
  }
}

// =============================================================================
// Modifiers
// =============================================================================
// =============================================================================
// States
// =============================================================================

