// =============================================================================
// Root
// =============================================================================
.c-upload {
  display: flex;
  flex-direction: column;
}

// =============================================================================
// Elements
// =============================================================================

.c-upload-mobile {
  display: inline;
  @screen tablet {
    display: none;
  }
}
.c-upload-desktop {
  display: none;
  @screen tablet {
    display: inline;
  }
}

.c-upload__field {
  display: block;
  border: 0.2rem dashed theme('colors.grayscales.gray4');
  padding: 4rem;
  text-align: center;
  margin-bottom: 1rem;
}

.c-upload__dragdrop {
  display: none;
  border: 2px dashed theme('colors.grayscales.gray4');
  cursor: pointer;
  padding: 2.8rem;
  margin-bottom: 2rem;
  text-align: center;

  @screen tablet {
    display: block;
  }
}

.c-upload__dragdrop_action-name {
  @apply h5-base;
  color: theme('colors.grayscales.gray3');
}

.c-upload__dragdrop_select-files {
  pointer-events: none;
  @apply paragraph-base;
  color: theme('colors.cta.base');
  text-decoration: underline;
}

.c-upload__title-container {
  @apply h7-base;
  @apply pb-small;
}

.c-upload__title {
  @apply h7-base;
  @apply mr-small;

  .c-tooltip__placeholder {
    @apply ml-small;
  }
}

.c-upload__explanation-files {
  pointer-events: none;
  @apply small-base;
  color: theme('colors.grayscales.gray2');
  font-weight: normal;
  margin-left: 0.8rem;
}

.no-pointer-events {
  pointer-events: none;
}

.c-upload__empty {
  background-color: theme('colors.grayscales.gray5') !important;
  border: none;
  padding: 0.5rem;

  @screen tablet {
    background-color: theme('colors.grayscales.white') !important;
  }
}

.c-upload__row {
  background-color: theme('colors.grayscales.gray6');

  @screen tablet {
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: theme('colors.grayscales.white');
  }

  .c-upload__toggle-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 0.8rem;
    cursor: pointer;
    &.is-error {
      color: theme('colors.ui.red1');
    }

    .c-upload__value-text {
      display: inline;
    }
  }

  .c-upload__toggle {
    margin-left: auto;
    cursor: pointer;
  }

  > div {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem 0 3rem;
    width: 100%;
  }

  > div:first-of-type {
    padding: 0.5rem 1rem;
    background-color: theme('colors.grayscales.gray5');
    display: grid;
    grid-template-columns: auto max-content;

    span {
      display: block;
      margin-left: auto;
    }
  }

  .c-upload__name {
    @apply small-bold;
  }

  .c-upload__value {
    word-break: break-word;
    padding-bottom: 0.5rem;
    border-bottom: 0.1rem dashed theme('colors.grayscales.gray4');
    padding-right: 0.5rem;
    text-overflow: ellipsis;

    .c-upload__value-text {
      display: inline;
    }
  }

  .c-upload__actions {
    display: flex;
    padding-top: 0.1rem;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    color: theme('colors.cta.base');

    .c-upload__action {
      width: 100%;
      cursor: pointer;
      padding: 0.5rem 0;
      margin-right: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      border-top: 0.1rem dashed theme('colors.grayscales.gray4');
      color: theme('colors.cta.base');

      @screen tablet {
        width: auto;
      }

      &:hover {
        color: theme('colors.cta.base');
      }

      &:first-child {
        border-top: none;
      }

      &:last-child {
        margin-right: 0;
      }

      &-icon {
        width: 1.3rem;
        height: 1.3rem;
      }
    }
  }

  .c-upload__action-name {
    display: block;
    margin-right: 1rem;
  }

  .c-upload__row--closed {
    display: none;
  }

  .c-upload__cell {
    max-width: 100% !important;
  }
}

.c-upload__rows {
  @apply small-base;

  display: flex;
  flex-direction: column;
  background-color: theme('colors.grayscales.gray6');
}

.c-upload__error_message {
  color: theme('colors.ui.red1');
  background-color: theme('colors.grayscales.gray5');
  padding: 0.5rem 1rem;

  @screen tablet {
    background-color: theme('colors.grayscales.white');
  }
  &:before {
    @apply fontawesome-small;
    @apply fontawesome-base;
    content: '\f06a';
    padding-right: 1.3rem;
    color: theme('colors.ui.red1');
  }

  .c-upload__error_message-text {
    display: inline;
  }
}

.c-upload__info-icon {
  @apply fontawesome-base;
  color: theme('colors.cta.base');
  margin-left: 0.4rem;
  cursor: pointer;
}

// =============================================================================
// Modifiers
// =============================================================================

// =============================================================================
// States
// =============================================================================

.c-upload__row .c-upload__value--states {
  &.is-succes {
    text-decoration: underline;
    color: theme('colors.cta.base');
    cursor: pointer;
    /* Bij te lange tekst afbreken met ellipsis */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.is-succes:before {
    @apply fontawesome-small;
    @apply fontawesome-base;
    content: '\f15b';
    padding-right: 1.6rem;
    min-width: 3rem !important;
  }
  &.is-loading:before {
    @apply fontawesome-small;
    @apply fontawesome-base;
    content: '\f021';
    display: inline-grid;
    color: theme('colors.cta.base');
    margin-left: 0.2rem;
    margin-right: 1rem;
    animation: fa-spin 2s infinite linear;
  }
  &.is-error:before {
    @apply fontawesome-small;
    @apply fontawesome-base;
    content: '\f06a';
    padding-right: 1.6rem;
    color: theme('colors.ui.red1');
  }
}

.c-upload__file {
  &.is-succes:before {
    @apply fontawesome-small;
    @apply fontawesome-base;
    content: '\f15b';
    padding-right: 1.6rem;
    min-width: 3rem !important;
  }

  &.is-loading:before {
    @apply fontawesome-small;
    @apply fontawesome-base;
    content: '\f021';
    display: inline-grid;
    color: theme('colors.cta.base');
    margin-left: 0.2rem;
    margin-right: 1rem;
    animation: fa-spin 2s infinite linear;
  }
  &.is-error:before {
    @apply fontawesome-small;
    @apply fontawesome-base;
    content: '\f06a';
    padding-right: 1.6rem;
    color: theme('colors.ui.red1');
  }
}

.c-upload__dragdrop--dragover {
  background-color: theme('colors.grayscales.gray5');
}

.c-upload__footer {
  background-color: theme('colors.grayscales.gray5');
}

.c-upload__additional_action {
  padding: 0.8rem;
  background-color: theme('colors.grayscales.gray5');
}

.c-upload__footer__left_loading {
  padding: 0.5rem 1rem;
  display: flex;
  .c-upload__footer__left_loading_icon {
    color: theme('colors.cta.base');
    padding-right: 1.2rem;
  }
  .c-upload__footer__left_loading_text {
    @apply small-base;
  }
}

@screen tablet {
  .c-upload__title {
    @apply h5-base;
  }

  .c-upload__header,
  .c-upload__footer {
    @apply h6-base;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: theme('colors.grayscales.gray5');

    padding: 1rem;

    div.c-upload__footer__left {
      flex: 1;
    }
    div.c-upload__footer__right {
      flex: 2;
      text-align: right;
    }
    .c-upload__footer__left_loading {
      padding: 0;
    }
  }

  .c-upload__row {
    padding: 1rem;
    display: grid;

    .c-upload__row--closed {
      display: inherit;
    }

    > div {
      padding: 0;
    }

    > div:first-of-type {
      padding: 0;
      background: none;
      display: grid;
      grid-template-columns: auto auto;

      span {
        display: none;
      }
    }

    .c-upload__toggle {
      display: none;
    }

    .c-upload__actions {
      display: flex;
      color: theme('colors.grayscales.gray1');
      flex-direction: row;

      .c-upload__action {
        padding: inherit;
        display: inherit;
        margin-right: 1.6rem;
        border-top: none;

        &:first-child {
          padding-bottom: 0;
        }
      }

      .c-upload__action-name {
        display: none;
      }
    }

    .c-upload__value {
      border-bottom: none;
    }
  }

  .c-upload__row--closed {
    display: block;
  }

  .c-upload__cell {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    justify-self: start;
  }
}
