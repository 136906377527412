.c-highlight-list {
  background-color: theme('colors.grayscales.gray5');

  .highlight-list__container {
    width: 100%;
    display: block;

    @screen tablet {
      > ngx-ov-highlight-item {
        width: 25%;
        display: inline-block;
        vertical-align: top;
      }
    }
  }
}
