.c-wizard {
  ngx-ov-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @screen tablet {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
    ngx-ov-checkbox-nested:not(:last-child) {
      margin-bottom: 1.6rem;
      @screen tablet {
        margin-bottom: 0;
      }
    }
    ngx-ov-checkbox-nested {
      min-height: 100%;
      cursor: pointer;

      .c-checkbox--selected,
      & :hover {
        box-shadow: 0px 0px 0px 2px theme('colors.cta.base') inset;
      }
    }
  }

  .c-checkbox__container {
    line-height: 3rem;
    margin-bottom: 0;
    align-items: center;
    display: flex;

    @screen tablet {
      align-items: flex-start;
    }

    .c-checkbox__toggle {
      order: 3;
      line-height: 1.1rem;
      margin-bottom: 0;
    }

    .c-checkbox__label {
      order: 2;
      flex: 1;
      line-height: 3rem;
      margin-bottom: 0;
    }

    .c-checkbox__icon {
      order: 1;
      padding-right: 1.6rem;
      line-height: 3rem;
      display: flex;
      align-items: center;

      i {
        font-size: 2.4rem !important;
        @apply fontawesome-medium;
      }
      .fas {
        line-height: inherit;
      }
    }
  }

  .c-checkbox {
    @apply h5-base;
    text-align: left;
    color: theme('colors.grayscales.gray2');
    border-radius: 0.4rem;
    position: relative;
    pointer-events: auto;
    background-color: theme('colors.grayscales.gray5');
    width: auto;
    min-height: 100%;
    display: block;
    padding: 1.2rem;
    & :hover {
      box-shadow: none;
    }
  }

  .c-checkbox__box {
    line-height: 2.6rem;
  }

  .c-checkbox__box:before {
    top: 1rem;
  }

  .c-checkbox__toggle_box {
    margin: -1rem 0.5rem;
    width: 2rem;
    height: 2rem;

    @screen tablet {
      margin: 0.5rem;
    }

    &::after {
      width: 2rem;
      height: 2rem;
    }
    &::before {
      font-size: 1.2rem;
      left: 0.9rem;
      top: 1rem;
    }
  }
}

.c-wizard {
  ngx-ov-checkbox-group {
    ngx-ov-checkbox-nested {
      flex: 1;
    }
  }
}

.c-wizard.c-wizard__col2 {
  ngx-ov-checkbox-group {
    ngx-ov-checkbox-nested {
      @screen tablet {
        flex: 0 0 calc(50% - 2.4rem);
        margin: 0.8rem;
      }
    }
  }
}

.c-wizard.c-wizard__col3 {
  ngx-ov-checkbox-group {
    ngx-ov-checkbox-nested {
      @screen tablet {
        flex: 0 0 calc(33.333333% - 2.4rem);
        margin: 0.8rem;
      }
    }
  }
}
