.mat-step-label {
  @screen toTablet {
    display: none !important;
  }
}

/* Formulier elementen outlines */
.mat-horizontal-content-container {
  overflow: visible !important;
}
