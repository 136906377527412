// =============================================================================
// Unordered list - (no classes)
// =============================================================================
/**
 * Info
 * -----
 * Contains the default slicing for <ul>
 */

// =============================================================================
// Unordered
// =============================================================================
ul {
  // Remove the standard margin from ul
  margin: 0;

  /**
	 * Slicing applied when there is no class applied. For example: rendered in
	 * a WYSIWYG.
	 */
  &:not([class]) {
    // @include c-list();
  }

  li {
    /**
		 * Slicing applied when there is no class applied. For example: rendered in
		 * a WYSIWYG.
		 */
    &:not([class]) {
      // @include c-list__item();
    }
  }
}

ul.u-list {
  margin: 0;
  padding: 0;
  padding-left: 2rem;

  i {
    @apply fontawesome-xsmall;
  }

  li {
    ul {
      padding-left: 0rem;
    }
  }
}

ul.u-list-overview {
  margin: 0;
  padding: 0;
  padding-left: 2rem;

  i {
    @apply fontawesome-xsmall;
  }

  li {
    padding: 0.2rem 0;
    @screen desktop {
      padding: 0.4rem 0;
    }
  }

  li span.fa-li {
    line-height: 2.2rem;
  }

  a {
    cursor: pointer;
  }
}
