// =============================================================================
// Paragraph
// =============================================================================

/**
 * Info
 * -----
 * Contains slicing for text-like tags like: p, small, strong, ...
 *
 * IMPORTANT TO NOTE!!!
 * -----
 * Do not specify any padding or margin on these tags to avoid having to
 * override them in every component.
 */

// =============================================================================
// P
// =============================================================================
p,
.ov-ui-p {
  @apply paragraph-base;

  margin: 0;
  /**
	 * Slicing applied when there is no class applied. For example: rendered in
	 * a WYSIWYG.
	 */
  // &:not([class]) {}
}

.ov-ui-p-bold {
  @apply paragraph-bold;
}

// =============================================================================
// Small
// =============================================================================
small,
.ov-ui-p-small {
  @apply small-base;

  /**
	 * Slicing applied when there is no class applied. For example: rendered in
	 * a WYSIWYG.
	 */
  // &:not([class]) {}
}

.ov-ui-p-small-bold {
  @apply small-bold;
}

.ov-ui-p-medium {
  color: theme('colors.grayscales.gray3');
}
