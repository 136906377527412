// =============================================================================
// Root
// =============================================================================
.c-internal-footer {
  @apply small-base;
  width: 100%;
  padding: 2rem 1rem;
  border-top: 0.1rem solid theme('colors.grayscales.gray4');
}

// =============================================================================
// Elements
// =============================================================================
.c-internal-footer__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  list-style-type: none;

  @screen tablet {
    flex-direction: row;
    justify-content: center;

    li + li:before {
      content: ' | ';
      padding: 0 1rem;
      color: theme('colors.grayscales.gray4');
    }
  }
}

.c-internal-footer__list-item {
  width: 100%;
  text-align: center;
  color: theme('colors.grayscales.gray3');
  display: inline-block;
  @screen tablet {
    display: inline;
  }


}
