ngx-ov-toggle-button-group {
  display: flex;
  flex-direction: column;

  @screen tablet {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.6rem;
    justify-content: center;
    margin: 0.8rem 0;
  }

  ngx-ov-toggle-button {
    min-height: 100%;
  }

  ngx-ov-toggle-button:not(:last-child) {
    margin-bottom: 1.6rem;
    @screen tablet {
      margin-bottom: 0;
    }
  }
}

.c-toggle-button__col2 {
  ngx-ov-toggle-button {
    @screen tablet {
      flex: 0 0 calc(50% - 1.6rem);
    }
  }
}

.c-toggle-button__col3 {
  ngx-ov-toggle-button {
    @screen tablet {
      flex: 0 0 calc(33.333333% - 1.6rem);
    }
  }
}

.c-toggle-button {
  @apply h5-base;
  text-align: left;
  color: theme('colors.grayscales.gray2');
  border-radius: 0.4rem;
  position: relative;

  background-color: theme('colors.grayscales.gray5');
  width: auto;
  min-height: 100%;
  display: block;
  padding: 1.2rem;

  cursor: pointer;

  &--selected {
    box-shadow: 0px 0px 0px 2px theme('colors.cta.base') inset;

    .c-toggle-button__checkbox {
      visibility: initial !important;
      display: inline !important;
    }
  }

  &:focus-visible {
    box-shadow: none;
    outline: 3px solid theme('colors.cta.outline');
    outline-offset: 2px;
  }

  .c-toggle-button__label {
    line-height: 3rem;
    margin-bottom: 0;
  }
}

.c-toggle-button__container {
  display: flex;
  margin-bottom: 0;
  line-height: 3rem;

  .c-toggle-button__toggle {
    order: 3;
    line-height: 3rem;
  }
  .c-toggle-button__checkbox {
    order: 4;
    line-height: 3rem;
    align-self: center;
    visibility: hidden;

    @screen tablet {
      align-self: flex-start;
      padding-top: 0.3rem;
    }

    .c-checkbox__container {
      line-height: 3rem;
      margin-bottom: 0;
      //align-items: center;

      .c-checkbox__toggle {
        order: 3;
        line-height: 1.1rem;
        margin-bottom: 0;
      }

      .c-checkbox__label {
        order: 2;
        flex: 1;
        line-height: 3rem;
        margin-bottom: 0;
      }

      .c-checkbox__icon {
        order: 1;
        padding-right: 1.2rem;
        line-height: 3rem;
        display: flex;
        align-items: center;

        i {
          font-size: 2.4rem !important;
          @apply fontawesome-medium;
        }
        .fas {
          line-height: inherit;
        }
      }
    }

    .c-checkbox {
      @apply h5-base;
      color: theme('colors.grayscales.gray2');
      border-radius: 0.4rem;
      position: relative;

      background-color: theme('colors.grayscales.gray5');
      width: auto;
      display: block;

      & :hover {
        box-shadow: none;
      }
    }

    .c-checkbox__box {
      line-height: 2.6rem;
    }

    .c-checkbox__box:before {
      top: 1rem;
    }

    .c-checkbox__toggle_box {
      margin: 0.3rem 1.6rem;
      &::after {
        border-radius: 50%;

        width: 2.2rem;
        height: 2.2rem;
      }
      &::before {
        font-size: 1.6rem;
        left: 0.9rem;
        top: 1rem;
      }
    }

    &::before {
      font-size: 1.6rem;
    }
  }

  .c-toggle-button__icon {
    order: 1;
    padding-right: 1.6rem;
    line-height: 3rem;
    display: flex;
    align-items: center;

    @screen tablet {
      align-self: flex-start;
      padding-top: 0.4rem;
    }

    i {
      font-size: 2.4rem !important;
      @apply fontawesome-medium;
    }
  }

  .c-toggle-button__label-container {
    order: 2;
    align-self: center;
    margin: 0;
    margin-right: auto;
  }
}
