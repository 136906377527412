// =============================================================================
// Root
// =============================================================================
.c-link {
  @apply paragraph-base;

  color: theme('colors.cta.base');

  text-decoration: none;
  padding: 0.8rem;
  @screen tablet {
    padding: 0;
  }

  &:hover {
    text-decoration: underline;

    cursor: pointer;
  }
}

// =============================================================================
// Elements
// =============================================================================
.c-link__icon-before {
  margin-right: 1.2rem;
}

.c-link__icon-after {
  margin-left: 1.2rem;
}

// =============================================================================
// Modifiers
// =============================================================================

a:focus-visible {
  box-shadow: none;
  outline: 3px solid theme('colors.cta.outline');
  outline-offset: 2px;
}

// =============================================================================
// States
// =============================================================================
.c-link.is-disabled {
  color: theme('colors.grayscales.gray3');

  &:hover {
    text-decoration: none;

    cursor: default;
  }
}
