// =============================================================================
// Img - (no classes)
// =============================================================================
img {
  max-width: 100%;
  height: auto;

  /**
	 * Slicing applied when there is no class applied. For example: rendered in
	 * a WYSIWYG.
	 */
  // &:not([class]) {}
}
