/* stylelint-disable scss/dollar-variable-pattern */
// =============================================================================
// Utils
// =============================================================================
/**
 * Info
 * -----
 * The config file for declaring and managing the classes for the utilities.
 *
 * Dependencies
 * -----
 *
 */

$default-spacing-xsmall: 0.4rem;
$default-spacing-small: 0.8rem;
$default-spacing-medium: 1.6rem;
$default-spacing-large: 2rem;
$default-spacing-xlarge: 3.2rem;

$default-spacings: (
  xsmall: $default-spacing-xsmall,
  small: $default-spacing-small,
  medium: $default-spacing-medium,
  large: $default-spacing-large,
  xlarge: $default-spacing-xlarge,
  10: 1rem,
  20: 2rem,
  30: 3rem,
  40: 4rem
);

$default-padding-margins: (
  all: $default-spacings,
  top: $default-spacings,
  right: $default-spacings,
  bottom: $default-spacings,
  left: $default-spacings
);
