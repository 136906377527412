// =============================================================================
// Root
// =============================================================================
.c-flyout {
  position: relative;
  max-width: 20rem;

  padding: 0 0.8rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  text-decoration: none;
  color: inherit;

  cursor: pointer;

  &__overlay {
    height: -webkit-fill-available;
    height: 100vh;
    width: 100vw;
    width: -webkit-fill-available;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-index-600 -1;
    opacity: 0.3;
    background-color: theme('colors.grayscales.gray1');

    @screen mobile {
      display: none;
    }
  }

  &__bottom-navigation {
    background-color: theme('colors.grayscales.gray6');

    .c-flyout-item {
      &:hover {
        @apply paragraph-bold;
      }
    }
  }
}

.c-header .c-flyout {
  height: 100%;
}

// =============================================================================
// Elements
// =============================================================================

.c-flyout__content {
  position: absolute;
  right: -1rem;

  width: fit-content;
  min-width: 20rem;
  margin: 2rem 0;

  background: theme('colors.grayscales.white');

  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);

  text-align: left;
  white-space: normal;

  z-index: $z-index-600;

  @screen toMobile {
    min-width: initial !important;
    width: 100vw;
    width: -webkit-fill-available;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    margin: 0;
  }
  @screen mobile {
    top: 3rem;
  }

  &--dark .c-flyout__header {
    @apply h7-uppercase;

    background: theme('colors.primary.dark');

    display: flex;
    flex-direction: row;
    padding: 1.6rem 0.8rem;

    .c-flyout__header-title {
      color: theme('colors.grayscales.white');
    }

    .c-flyout__header-close {
      color: theme('colors.grayscales.white');
      &:hover {
        color: theme('colors.grayscales.gray6');
      }
    }
  }
}

@screen toMobile {
  .c-flyout__content--mobile-fullscreen {
    height: 100vh;
    height: -webkit-fill-available;
  }
}

@screen mobile {
  .c-flyout__content:before,
  .c-flyout__content:after {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 1.5rem;
    border: 11px solid transparent;
    border-bottom-color: theme('colors.grayscales.gray5');
  }

  .c-flyout__content--dark:before,
  .c-flyout__content--dark:after {
    border-bottom-color: theme('colors.primary.dark');
  }
}

.c-flyout__trigger__container {
  display: flex;
  flex-direction: row;

  .c-flyout__trigger_layout {
    width: 3rem;
    height: 3rem;

    background: theme('colors.grayscales.gray5');

    border-radius: 50%;

    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .c-flyout__trigger {
    position: relative;
    display: flex;


    > p,
    ng-template {
      display: inline-block;

      vertical-align: middle;

      font-size: 1.3rem;
      font-weight: 800;
    }
  }

  .c-flyout__menu {
    display: none;
  }

  @screen tablet {
    .c-flyout__menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 0.8rem;
    }
  }

  .c-flyout__trigger__label,
  .c-flyout__trigger__label-mobile {
    padding-left: 0.4rem;
    line-height: 3rem;
    @apply h7-uppercase;
    align-self: center;
  }
  .c-flyout__trigger__label {
    display: none;
    @screen tablet {
      display: inline;
      padding-right: 0.8rem;
      padding-left: 0;
    }
  }
  .c-flyout__trigger__label-mobile {
    @screen tablet {
      display: none;
    }
  }
}

.c-flyout__header {
  @apply h7-uppercase;

  padding: 1.6rem 0.8rem;
  background: theme('colors.grayscales.gray5');

  display: flex;
  flex-direction: row;

  cursor: default;
}

.c-flyout--lite {
  &:before,
  &:after {
    border-bottom-color: theme('colors.grayscales.white');
  }
  .c-flyout__header,
  .c-flyout__header--dark {
    display: none;

    .c-flyout__header-title {
      visibility: hidden;
    }
  }
}

.c-flyout--header-hidden-desktop {
  .c-flyout__header,
  .c-flyout__header--dark {
    @screen mobile {
      display: none;
    }
  }

  &:before,
  &:after {
    border-bottom-color: theme('colors.grayscales.white');
  }
}

.c-flyout__header-title {
  flex: 100;
  color: theme('colors.grayscales.gray1');
}
.c-flyout__header-close {
  @apply fontawesome-medium;
  color: theme('colors.cta.base');
  cursor: pointer;
  padding-right: 0.8rem;

  &:hover {
    color: theme('colors.cta.dark');
  }

  @screen mobile {
    display: none;
  }
}

.c-flyout-item {
  @apply paragraph-base;
  color: theme('colors.grayscales.gray1');
  display: flex;
  flex-direction: row;

  margin: 0;
  padding: 1.5rem 0.8rem;

  border-bottom: 0.1rem solid theme('colors.grayscales.gray5');

  &:hover {
    background-color: theme('colors.grayscales.gray6');
  }

  * {
    align-self: center;
    text-align: left;
  }

  &--large-version {
    width: 100%;
    max-width: 100%;

    @screen mobile {
      width: 36rem;
      max-width: 36rem;
    }
  }

  &__content--large-version {
    width: 100%;
    max-height: 10rem;
    overflow: hidden;
  }
}

ngx-ov-flyout-item:last-child {
  border: none;

  .c-flyout-item {
    border-bottom: 0;
  }
}

// =============================================================================
// Modifiers
// =============================================================================

// =============================================================================
// States
// =============================================================================

.c-flyout--secondary {
  padding: 0;
  padding-left: 2rem;

  .c-flyout__trigger__container {
    height: 100%;

    .c-flyout__trigger_default {
      &::after {
        @apply fontawesome-base;
        @apply fontawesome-medium;
        content: '\f059';
        padding-right: 1.2rem;
      }
    }

    .c-flyout__trigger {
      width: auto;
      min-height: 4.3rem;
      height: 100%;
      background: theme('colors.primary.dark');
      color: theme('colors.grayscales.white');

      p {
        display: none;
      }

      border-radius: 0;

      .c-flyout__content {
        right: 1rem;
        color: theme('colors.grayscales.gray1');
      }

      &:before {
        content: '';
        border-top: 4.3rem solid theme('colors.primary.dark');
        border-left: 2rem solid transparent;
        width: 0;
        height: 0;
        position: absolute;
        right: 100%;
        top: 0;
        padding-right: 0.4rem;
      }

      > p,
      ng-template {
        font-size: 2rem;
      }

      @screen tablet {
        p {
          @apply h7-uppercase;
          display: block;
          padding: 0 0.8rem;
        }
      }
    }
  }
}

@screen mobile {
  .c-flyout__content--right {
    left: -1rem;
  }
  .c-flyout__content--right:before,
  .c-flyout__content--right:after {
    right: auto;
    left: 1.5rem;
  }
}
