.mat-step-header .mat-step-icon {
  background-color: theme('colors.grayscales.gray4');
  color: theme('colors.grayscales.gray3');
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: theme('colors.cta.base');
  color: theme('colors.grayscales.white');
}

.mat-step-icon-state-number {
  @apply h5-base;
}

.mat-step-text-label {
  @apply paragraph-base;
}

.mat-step-icon {
  width: 5.1rem;
  height: 5.1rem;
}

.mat-vertical-stepper-content {
  padding: 0.5rem;
  visibility: visible !important;
  height: 100% !important;
}

mat-vertical-stepper {
  .mat-step {
    padding: 1.4rem;
  }

  .mat-stepper-vertical-line::before {
    top: 0px;
    bottom: -28px;
    left: -24px;
  }

  .mat-vertical-content {
    padding-top: 1.6rem;
    padding-bottom: 0;
    padding-left: 0;
  }
  .mat-step-header {
    padding: 0;
    .mat-step-label,
    .mat-step-label .mat-step-text-label {
      @apply h5-base;

      display: inline-block !important;
      overflow: inherit !important;
      color: theme('colors.grayscales.gray4');
    }

    .mat-step-label.mat-step-label-active {
      color: theme('colors.ui.green1');

      .mat-step-text-label {
        color: theme('colors.ui.green1');
      }
    }

    .mat-step-text-label {
      margin-top: 0.6rem;
    }

    .mat-step-icon-state-done.mat-step-icon {
      background-color: theme('colors.ui.green1');
    }

    .cta .mat-step-label.mat-step-label-selected,
    .mat-step-label.mat-step-label-selected .mat-step-text-label {
      color: theme('colors.cta.base');
    }
  }
}
