// =============================================================================
// Root
// =============================================================================
.c-button {
  @apply h6-base;

  width: 100%;
  padding: 1.1rem 2rem;
  display: block;

  text-decoration: none;
  text-align: center;

  color: theme('colors.grayscales.white');
  background-color: theme('colors.cta.base');
  border: 0.1rem solid theme('colors.cta.base');

  transition:
    background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out;

  cursor: pointer;

  &:hover {
    background-color: theme('colors.cta.dark');
    border-color: theme('colors.cta.dark');
  }

  @screen tablet {
    width: auto;
    display: inline-block;
    box-sizing: border-box;
  }
}

// =============================================================================
// Elements
// =============================================================================
.c-button__text,
.c-button__icon {
  display: inline-flex;
  cursor: pointer;
}

.c-button__icon--before {
  margin-right: 1.2rem;
}

.c-button__icon--after {
  margin-left: 1.2rem;
}

.c-button__icon--only {
  margin-left: 1.2rem;
  margin-right: 1.2rem;
}

// =============================================================================
// Modifiers
// =============================================================================
// This is one is exactly the same as the c-button
// .c-button--primary {
// }

.c-button--secondary {
  color: theme('colors.cta.base');
  background-color: theme('colors.grayscales.white');

  &:hover {
    border: 0.2rem solid;
    padding: 1rem 1.9rem;
    background-color: theme('colors.grayscales.white');
  }
}

.c-button--tertiary {
  color: theme('colors.cta.base');
  background-color: theme('colors.grayscales.white');
  border-color: theme('colors.grayscales.gray5');

  &:hover {
    background-color: theme('colors.grayscales.white');
    border-color: theme('colors.grayscales.gray4');
  }
}

.c-button--text {
  color: theme('colors.cta.base');
  background-color: transparent;
  border: none;

  &:hover {
    background-color: theme('colors.grayscales.gray5');
    border: none;
  }
}

.c-button--narrow {
  padding: 0.3rem 1rem;

  &.c-button--secondary:hover {
    padding: 0.2rem 0.9rem;
  }
}

.c-button--wide {
  padding-left: 6rem;
  padding-right: 6rem;

  &.c-button--secondary:hover {
    padding-left: 5.9rem;
    padding-right: 5.9rem;
  }
}

.c-button--block {
  width: 100%;
}

.c-button--fab {
  position: fixed;
  z-index: $z-index-300;
  width: auto;
  display: inline-block;

  &--bottom-center {
    left: 50%;
    bottom: 10%;
    transform: translate(-50%, 0);
  }

  &--bottom-start {
    bottom: 10%;
    left: 10%;
  }

  &--bottom-end {
    bottom: 10%;
    right: 10%;
  }
}

// =============================================================================
// States
// =============================================================================
.c-button {
  &.is-disabled,
  &:disabled {
    border: 0.1rem solid theme('colors.grayscales.gray5');

    color: theme('colors.grayscales.white');
    background-color: theme('colors.grayscales.gray4');

    cursor: not-allowed;

    .c-button__text {
      cursor: not-allowed;
    }

    &:hover {
      border-color: theme('colors.grayscales.gray5');
    }

    &.c-button--secondary,
    &.c-button--tertiary,
    &.c-button--text {
      color: theme('colors.grayscales.gray4');
      background-color: theme('colors.grayscales.white');
    }
  }

  &.is-error:not(.c-button--secondary):not(.c-button--tertiary):not(
      .c-button--text
    ) {
    background-color: theme('colors.ui.red1');
    border-color: theme('colors.ui.red1');
  }
  &.is-error.c-button--secondary {
    border-color: theme('colors.ui.red1');
    color: theme('colors.ui.red1');
  }
  &.is-error.c-button--tertiary,
  &.is-error.c-button--text {
    color: theme('colors.ui.red1');
  }

  &.is-loading {
    display: flex;

    background-color: theme('colors.grayscales.gray4');
    border-color: theme('colors.grayscales.gray4');
  }

  &--is-not-clickable {
    pointer-events: none;
  }
}

.c-button:focus-visible {
  box-shadow: none;
  outline: 3px solid theme('colors.cta.outline');
  outline-offset: 2px;
  z-index: $z-index-top;
}
