// =============================================================================
// Root
// =============================================================================

// =============================================================================
// Elements
// =============================================================================

.c-menubar__nav{
  background: theme('colors.grayscales.gray5') ;
  &.is-vo-theme {
    background: theme('colors.flemish.dark') ;
  }
  @screen toTablet {
    position: fixed;
    z-index: $z-index-500;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.c-menubar__div{
  @screen tablet {
    margin-left: 20rem;
  }
}

.c-menubar__navigation {
  display: flex;
  padding: 0;
  border-bottom: 0.2rem solid theme('colors.grayscales.gray5');
  &.is-vo-theme {
    border-bottom: 0.2rem solid  theme('colors.flemish.dark') ;
  }
  list-style: none;
  @screen toTablet {
    justify-content: space-evenly;
    //remove bottom line and add top line
    border-bottom: 0 ;
    border-top: 0.2rem solid theme('colors.grayscales.gray5');
    &.is-vo-theme {
      border-top: 0.2rem solid  theme('colors.flemish.dark') ;
    }
  }
  .c-menubar__navigation-item{
    @screen toTablet{
      width: 100%;
      text-align: center;
    }
  }
  .c-menubar__navigation-item:not(:first-child) {
    .c-menubar__navigation-link {
      &::before {
        content: '';
        position: absolute;
        top: calc(50% - (1.6rem / 2));
        bottom: 0;
        left: 0;
        width: 0.05rem;
        height: 1.6rem;
        background: theme('colors.grayscales.gray4');
        &.is-image{
          top: calc(50% - (6rem / 2));
          height: 6rem;
        }
      }
      &.is-vo-theme {
        &::before {
          background: theme('colors.flemish.dark');
        }
      }
    }
  }
}
.c-menubar__navigation-link {
  @apply paragraph-bold;
  color: theme('colors.cta.base');
  position: relative;
  display: block;
  margin-bottom: -0.2rem;
  padding: 1.2rem 1.2rem;
  border-bottom: 0.2rem solid transparent;
  transition: border-bottom 0.2s;
  @screen toTablet{
    padding: 1.2rem 0;
    border-bottom: 0;
    border-top: 0.2rem solid transparent;
  }
  &:hover {
    border-bottom: 0.2rem solid theme('colors.cta.base');
    @screen toTablet{
      //remove bottom line and add top line
      border-bottom: 0 ;
      border-top: 0.2rem solid theme('colors.cta.base');
    }
    cursor: pointer;
  }
  &.is-vo-theme {
    color: theme('colors.grayscales.gray1');
  }
  .c-menubar__navigation-image{
    font-size: 2rem;
    @screen tablet{
      display: none;
    }
  }
}
.c-menubar__navigation-item--active{
  border-bottom: 0.2rem solid theme('colors.grayscales.gray1');
  @screen toTablet{
    //remove bottom line and add top line
    border-bottom: 0 ;
    border-top: 0.2rem solid theme('colors.grayscales.gray1');
    color: theme('colors.grayscales.gray1');
  }
  .c-menubar__navigation-link{
    @screen toTablet{
      color: theme('colors.grayscales.gray1');
    }
  }
}
