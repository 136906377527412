// =============================================================================
// Root
// =============================================================================
.c-snackbar-container {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}


.c-snackbar {
  @apply paragraph-base;
  display: flex;
  flex-direction: column;
  width: 35rem;

  background-color: theme('colors.grayscales.gray5');
  border: 0.1rem solid theme('colors.grayscales.gray5');

  text-align: left;
  position: relative;
  margin: 1.6rem 2rem 0rem 2rem;


  z-index: $z-index-400 + 50;


  @screen mobile {
    width: 35rem;
  }
}

.c-snackbar__body {
  display: flex;
  flex-direction: row;
  padding: 1.6rem;
  text-align: left;
  @screen mobile {
    padding: 2.4rem 1.6rem;
  }
}

// =============================================================================
// Elements
// =============================================================================
.c-snackbar__content {
  width: 100%;
  margin-left: 1.5rem;
}

.c-snackbar__icon {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 0.4rem;
  padding-right: 3.2rem;

  .c-icon {
    @apply fontawesome-xlarge;
    width: 2rem;
    height: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    background-color: theme('colors.grayscales.white');
    @screen mobile {
      @apply fontawesome-xlarge;
      width: 3.7rem;
      height: 3.7rem;
    }
  }
}

.c-snackbar__content_toprow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .c-icon__closing {
    @apply fontawesome-small;
    padding-left: 0.8rem;
    color: theme('colors.grayscales.gray3');
    cursor: pointer;
  }

  .c-snackbar__title {
    @apply paragraph-bold;
    @screen mobile {
      @apply h4-base;
    }

    color: theme('colors.grayscales.gray1');
  }

  .c-snackbar__message {
    @apply paragraph-base;

    color: theme('colors.grayscales.gray1');
  }
}

.c-snackbar__footer {
  padding-top: 0.8rem;
}

.c-snackbar__progressbar div {
  height: 4px;
  background-color: theme('colors.cta.dark');
  animation: roundtime linear forwards;
  transform-origin: right center;
}

@keyframes roundtime {
  to {
    transform: scaleX(0);
  }
}

// =============================================================================
// Modifiers
// =============================================================================
.c-snackbar--error {
  background-color: theme('colors.ui.red3');

  .c-icon {
    color: theme('colors.ui.red1');
  }
}

.c-snackbar--warning {
  background-color: theme('colors.ui.orange3');

  .c-icon {
    color: theme('colors.ui.orange1');
  }
}

.c-snackbar--success {
  background-color: theme('colors.ui.green3');

  .c-icon {
    color: theme('colors.ui.green1');
  }
}

.c-snackbar--small {
  width: fit-content;

  .c-snackbar__message,
  .c-snackbar__footer,
  .c-snackbar__progressbar {
    display: none;
  }
  .c-snackbar__icon {
    padding: 0.4rem 1rem 0 1rem;
    .c-icon {
      width: 1.4rem;
      height: 1.4rem;
    }
  }

  .c-snackbar__title {
    @screen mobile {
      @apply h5-base;
    }
  }
}

// =============================================================================
// Position
// =============================================================================

.c-snackbar--top-right {
  @screen mobile {
    bottom: unset;
    right: 0;
    left: unset;
    top: 0;
  }
}

.c-snackbar--top-left {
  @screen mobile {
    bottom: unset;
    right: unset;
    top: 0;
    left: 0;
  }
}

.c-snackbar--bottom-right {
  @screen mobile {
    bottom: 0;
    right: 0;
    left: unset;
    top: unset;
  }
}

.c-snackbar--bottom-left {
  @screen mobile {
    bottom: 0;
    right: unset;
    left: 0;
    top: unset;
  }
}
