// =============================================================================
// Heading
// =============================================================================

/**
 * Info
 * -----
 * Contains slicing for the h1 - h6 tags.
 *
 * IMPORTANT TO NOTE!!!
 * -----
 * Do not specify any padding or margin on these tags to avoid having to
 * override them in every component.
 */

h1,
h2,
h3,
h4,
h5,
h6,
.ov-ui-h1,
.ov-ui-h2,
.ov-ui-h3,
.ov-ui-h4,
.ov-ui-h5,
.ov-ui-h6,
.ov-ui-h7 {
  /**
	 * By default the normalize adds margin to the heading tags. This is
	 * unacceptable!
	 */
  margin: 0;
}

.u-reset-margin {
  margin: 0;
}

// =============================================================================
// H1
// =============================================================================
h1,
.ov-ui-h1 {
  @apply h1-base;
  margin-top: 0.8rem;
  margin-bottom: 3.6rem;

  @screen tablet {
    margin-top: 1.6rem;
    margin-bottom: 4.8rem;
  }
}

// =============================================================================
// H2
// =============================================================================
h2,
.ov-ui-h2 {
  @apply h2-base;
  margin-top: 0.8rem;
  margin-bottom: 2.8rem;

  @screen tablet {
    margin-top: 1.6rem;
    margin-bottom: 4rem;
  }
}

// =============================================================================
// H3
// =============================================================================
h3,
.ov-ui-h3 {
  @apply h3-base;
  margin-top: 0.8rem;
  margin-bottom: 2.4rem;

  @screen tablet {
    margin-top: 1.6rem;
    margin-bottom: 2.8rem;
  }
}

// =============================================================================
// H4
// =============================================================================
h4,
.ov-ui-h4 {
  @apply h4-base;
  margin-top: 0.8rem;
  margin-bottom: 2rem;

  @screen tablet {
    margin-top: 1.6rem;
    margin-bottom: 2.4rem;
  }
}

.ov-ui-h4-uppercase {
  @apply h4-base;
  text-transform: uppercase;
  margin-top: 0.8rem;
  margin-bottom: 2rem;

  @screen tablet {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
}

// =============================================================================
// H5
// =============================================================================
h5,
.ov-ui-h5 {
  @apply h5-base;
  margin-top: 0.8rem;
  margin-bottom: 2rem;

  @screen tablet {
    margin-top: 1.6rem;
    margin-bottom: 2.4rem;
  }
}

// =============================================================================
// H6
// =============================================================================
h6,
.ov-ui-h6 {
  @apply h6-base;

  &-underline {
    text-decoration: underline;
  }
  margin-top: 0.8rem;
  margin-bottom: 1.6rem;

  @screen tablet {
    margin-top: 1.6rem;
    margin-bottom: 2rem;
  }
}

// =============================================================================
// H7
// =============================================================================
.ov-ui-h7 {
  @apply h7-base;
  text-transform: uppercase;
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
  display: block;

  @screen tablet {
    margin-top: 1.6rem;
    margin-bottom: 2rem;
  }
}
