// =============================================================================
// Unordered list - (no classes)
// =============================================================================
/**
 * Info
 * -----
 * Contains the default slicing for <figure>
 */

// =============================================================================
// Unordered
// =============================================================================
figure {
  // Remove the default browser applied margin from the figure
  margin: 0;

  /**
	 * Slicing applied when there is no class applied. For example: rendered in
	 * a WYSIWYG.
	 */
  // &:not([class]) {}
}
