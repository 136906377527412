$base: 38px;

// =============================================================================
// Root
// =============================================================================
// .c-breadcrumb {
// }

// =============================================================================
// Elements
// =============================================================================
.c-breadcrumb__list {
  display: flex;
  padding: 1rem;

  list-style: none;
  background-color: theme('colors.grayscales.gray5');
  border: 1px solid theme('colors.grayscales.gray4');
}

ngx-ov-breadcrumb-item {
  padding-right: 1.6rem;
  position: relative;
  cursor: pointer;
  display: inline-flex;
}

ngx-ov-breadcrumb-item:not(:last-child):after {
  content: '\f054';
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
  color: theme('colors.grayscales.gray3');
  font-stretch: condensed;
  display: inline-block;
  padding-left: 1.6rem;
}

.c-breadcrumb__link {
  @apply paragraph-base;
  color: theme('colors.grayscales.gray1');
}

.c-breadcrumb__icon {
  margin: 0 0.5rem;

  color: theme('colors.grayscales.gray4');
}

// =============================================================================
// Modifiers
// =============================================================================
