// =============================================================================
// Root
// =============================================================================

.c-agenda-list {
  .c-agenda-list-item {
    margin-bottom: 2rem;
  }
}

.c-agenda-list-item {
  display: flex;
  flex-direction: column;
  background-color: theme('colors.grayscales.gray5');
  text-decoration: none;
  cursor: default;

  @screen mobile {
    display: flex;
    flex-direction: row;
    background-color: theme('colors.grayscales.gray5');
    text-decoration: none;
    cursor: default;
  }
}

// =============================================================================
// Elements
// =============================================================================
.c-agenda-list-item__date {
  @apply h4-base;
  padding-top: 0.9rem;
  padding-left: 2rem;
  padding-bottom: 0.9rem;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  text-transform: uppercase;
  text-decoration: none;

  @screen mobile {
    flex: 0 0 7.2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0;
  }

  .c-agenda-list-item__date-left {
    flex: 1;
  }

  .c-flyout__trigger__container .c-flyout__trigger {
    background-color: inherit;
    height: inherit;
  }
}

.c-agenda-list-item__dateformat {
  display: flex;

  @screen mobile {
    display: block;
  }
}

.c-agenda-list-item__content {
  padding: 1.2rem;
  flex: 100;

  @screen mobile {
    padding: 2rem 3.2rem 2rem 3.2rem;
  }
}

.c-agenda-list-item__content-right {
  padding: 2rem;
  @screen mobile {
    padding: 2rem 1rem 2rem 2rem;
  }
}

.c-agenda-list-item__title {
  @apply h5-base;
  padding-bottom: 0.4rem;
}

.c-agenda-list-item__text {
  color: theme('colors.grayscales.gray1');
}

// =============================================================================
// Modifiers
// =============================================================================
.c-agenda-list-item--with-link {
  .c-agenda-list-item__title {
    text-decoration: underline;
    color: theme('colors.cta.base');
  }

  &:hover {
    background-color: theme('colors.grayscales.gray6');

    .c-agenda-list-item__content,
    .c-agenda-list-item__date {
      cursor: pointer;
    }

    .c-agenda-list-item__title {
      text-decoration: none;
      color: theme('colors.cta.dark');
    }
  }
}

// =============================================================================
// States
// =============================================================================
