// =============================================================================
// Body
// =============================================================================
body,
.sb-show-main {
  @apply paragraph-base;

  overflow-x: hidden;

  color: theme('colors.grayscales.gray1');
}

*:focus-visible {
  box-shadow: none;
  outline: 3px solid theme('colors.cta.outline');
  outline-offset: 2px;
}

/* For browsers that don't support :focus-visible */
*:focus {
  box-shadow: none;
  outline: 3px solid theme('colors.cta.outline');
  outline-offset: 2px;
}

/* Remove :focus styling for browsers that do support :focus-visible */
*:focus:not(:focus-visible) {
  outline: none;
}