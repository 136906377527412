// Settings mobile
.modal {
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  width: -webkit-fill-available;
  left: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  flex-flow: row;
  z-index: $z-index-500;

  @screen mobile {
    height: -webkit-fill-available;
  }

  &__image {
    padding-bottom: 1rem;
    padding-top: 1rem;
    text-align: center;
  }

  &__overlay {
    height: 100vh;
    height: -webkit-fill-available;
    width: 100vw;
    width: -webkit-fill-available;

    position: fixed;
    top: 0;
    z-index: $z-index-500;

    opacity: 0.3;
    background-color: theme('colors.grayscales.gray1');
  }

  &__close {
    @apply fontawesome-medium;
    color: theme('colors.cta.base');
    cursor: pointer;
    padding-left: 0.8rem;

    &:hover {
      color: theme('colors.cta.dark');
    }
  }

  &__block {
    height: 100%;
    width: 100vw;
    width: -webkit-fill-available;

    display: flex;
    flex-direction: column;
    align-self: flex-end;
    z-index: $z-index-500;

    background-color: theme('colors.grayscales.white');
    word-break: break-word;
    padding: 1.6rem;

    &--not-fullscreen {
      height: fit-content;
    }

    @screen mobile {
      height: -webkit-fill-available;
      position: fixed;
    }

    h2 {
      word-wrap: break-word;
    }

    p {
      @apply paragraph-base;
    }
  }

  &__content {
    overflow-y: auto;
    margin: 1rem 0;
    flex: 100;
    text-align: left;
    font-weight: normal;

    &--allow-overflow {
      @screen mobile {
        overflow-y: visible;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    ngx-ov-button {
      margin-left: 1.6rem;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    @apply h2-base;

    &-icon {
      @apply fontawesome-medium;
      color: theme('colors.ui.red1');
      margin-right: 0.8rem;
    }
  }
}

// Settings > mobile
@screen mobile {
  .modal {
    &__block {
      left: 50%;
      bottom: 50%;
      transform: translate(-50%, 50%);
      height: auto;
      max-height: 90vh;
      &-small {
        width: 35.2rem;
        max-width: 95vw;
      }

      &-medium {
        width: 50rem;
        max-width: 95vw;
        max-height: 36rem;
      }

      &-large {
        width: 76.4rem;
        max-width: 95vw;
      }

      &-xlarge {
        width: 102.4rem;
        max-width: 95vw;
      }
    }

    &__content {
      max-height: 90vh;
    }

    &__footer {
      position: initial;
    }
  }
}

@screen mobile {
  .modal {
    &__footer {
      position: initial;
    }
  }
}
