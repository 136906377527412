.c-wizard-navigation {
  display: flex;
  flex-direction: row;

  &__title {
    justify-content: center;
    text-align: center;
    flex: 1;

    @apply h7-uppercase;
    color: theme('colors.grayscales.gray3');
  }

  &__nav-item {
    &--left {
      color: theme('colors.cta.base');
      cursor: default;

      text-align: start;
      justify-content: flex-start;
      flex: 1;
      display: flex;
    }
    &--pointer{
      cursor: pointer;
      width: fit-content;
      margin: 0;
    }

    &--right {
      color: theme('colors.cta.base');
      cursor: default;

      text-align: end;
      justify-content: flex-end;
      flex: 1;
      display: flex;
    }
  }
}
