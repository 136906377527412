.c-message-bar {
  position: absolute;
  bottom: 0;
  right: 0;
  outline: theme('colors.grayscales.gray4') solid 1px;
  margin-right: 1.6rem;
  width: 40%;
  max-width: 40%;
  max-height: 70%;

  @screen toTablet {
    position: fixed;
    z-index: $z-index-500;

    margin-right: 0;
    width: 100vw;
    width: -webkit-fill-available;

    height: 100vh;

    &:not(.is-open) {
      display: none;
    }
  }

  .c-message-bar__title {
    @apply h6-base;
    padding: 0.8rem 1.6rem;
    background: theme('colors.grayscales.gray6');
    display: flex;
    gap: 0.8rem;
    align-items: center;
    color: theme('colors.cta.base');

    @screen toTablet {
      @apply h3-base;
    }
  }

  .c-message-bar__content {
    overflow: scroll;
    background: theme('colors.grayscales.white');
    max-height: 800px;
    @screen toTablet {
      height: -webkit-fill-available;
    }
  }
}
