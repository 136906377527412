// =============================================================================
// Ordered list - (no classes)
// =============================================================================
/**
 * Info
 * -----
 * Contains the default slicing for <ol>
 */

// =============================================================================
// Unordered
// =============================================================================
ol {
  // Remove the default margins from ol
  margin: 0;

  /**
	 * Slicing applied when there is no class applied. For example: rendered in
	 * a WYSIWYG.
	 */
  // &:not([class]) {}
}
