// =============================================================================
// Root
// =============================================================================
.c-control-panel-item {
  width: 100%;
  border-bottom: 0.1rem solid theme('colors.grayscales.gray4');
  padding: 0rem 2rem;

  @screen tablet {
    border: none;
  }
}

// =============================================================================
// Elements
// =============================================================================
.c-control-panel-item__header {
  @apply paragraph-bold;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0rem;

  @screen tablet {
    color: theme('colors.grayscales.gray2');
  }
}

.c-control-panel-item__content {
  padding-bottom: 1rem;
}

.c-control-panel-item__collapsable {
  @apply fontawesome-small;
  color: theme('colors.grayscales.gray2');
}
// =============================================================================
// Modifiers
// =============================================================================

.c-control-panel-item__content--hidden {
  display: none;
  @screen tablet {
    display: block;
  }
}

// =============================================================================
// States
// =============================================================================
