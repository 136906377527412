// =============================================================================
// Root
// =============================================================================
.c-extra-info {
  @apply small-base;
  color: theme('colors.grayscales.gray3');

  a {
    @apply small-base;
  }
}
