// =============================================================================
// Root
// =============================================================================
.c-card-group {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;

  @screen tablet {
    width: 100%;
    flex-direction: row;
    ngx-ov-card {
      width: 100%;
    }

    .c-card {
      height: 100%;
    }
  }
}

.c-card {
  padding: 2rem;
  border: 0.1rem solid theme('colors.grayscales.gray6');
  border-top: 0;
  box-sizing: border-box;
  background: theme('colors.grayscales.white');
  cursor: default;
  display: flex;
  flex-direction: column;
  text-align: left;
}

// =============================================================================
// Elements
// =============================================================================
.c-card__title {
  @apply h5-base;
  color: theme('colors.grayscales.gray1');
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;

  &:hover {
    i {
      color: theme('colors.cta.dark');
    }
  }

  i {
    @apply fontawesome-medium;
    color: theme('colors.cta.base');
    margin-top: 0.2rem;
  }

  .c-card__title-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.8rem;

    .c-card__title-row {
      display: flex;
      flex-direction: row;
    }

    @screen tablet {
      flex-direction: row;
    }

    i {
      @apply fontawesome-medium;
      margin-top: 0.2rem;
      color: theme('colors.grayscales.gray4');
    }

    .c-card__icon-left {
      padding-right: 0.8rem; // + container gap of 8px
    }
  }

  .c-card__icon-right {
    padding-left: 1.6rem;
  }
}

.c-card__content {
  margin-top: 0.8rem;
}

.c-card__content:empty {
  display: none;
}

.c-card__content:not(:last-child) {
  margin-bottom: 2rem;
  flex: 1;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-card--secondary {
  background: theme('colors.grayscales.gray6');
  padding: 1.6rem;

  .c-card__content {
    margin-top: 1.2rem;
    @screen tablet {
      margin-top: 1.6rem;
    }
  }

  &.c-card--expandable {
    background: theme('colors.grayscales.white');
    border: 1px solid theme('colors.grayscales.gray4');
    box-shadow: 0px 10px 15px -3px rgba(26, 32, 44, 0.1),
      0px 4px 6px -2px rgba(26, 32, 44, 0.05);

    .c-card__title {
      cursor: pointer;
    }

    .c-card__content {
      @screen tablet {
        margin: 2.8rem 0;
      }
      margin: 2.4rem 0;
    }
  }
}

.c-card--with-link {
  .c-card__title {
    cursor: pointer;
  }
  .c-card__title .c-card__title-container div {
    color: theme('colors.cta.base');
    text-decoration: underline;
    &:hover {
      cursor: pointer;
      text-decoration: none;
      color: theme('colors.cta.dark');
    }
  }

  &.c-card--secondary {
    .c-card__title .c-card__title-container div {
      color: theme('colors.grayscales.gray1');
      text-decoration: none;

      &:hover {
        color: theme('colors.grayscales.gray1');
      }
    }
  }
}

.c-card--centered {
  .c-card__content {
    text-align: center;
  }

  .c-card__title-container {
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    div {
      text-align: center;
    }
  }
}

.c-card--left {
  .c-card__content {
    text-align: start;
  }

  .c-card__title-container {
    div {
      text-align: start;
    }
  }
}

.c-card--right {
  .c-card__content {
    text-align: end;
  }

  .c-card__title-container {
    margin-left: auto;
    div {
      text-align: end;
    }
  }
}
