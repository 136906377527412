// =============================================================================
// Elements
// =============================================================================
.c-ov-stepper__stepnumber {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  flex-shrink: 0;
  position: relative;
  margin-right: 0.8rem;
}

.c-ov-step__stepnumber-content {
  // Use absolute positioning to center the content, because it works better with text.
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  // We aren't using any flex features here, but using the `display: flex` prevents
  // the browser from adding extra whitespace at the bottom of the element.
  display: flex;
}

.c-ov-stepper__container {
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.c-ov-stepper__title {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  padding: 2.4rem;
}

.c-ov-stepper__chevron {
  margin-left: 8px;
}

.c-ov-stepper__content {
  padding: 0 2.4rem;
}

.c-ov-stepper__content_container {
  margin-left: 3.6rem;
  border: 0;
  position: relative;
}

.c-ov-stepper__vertical_line {
  &::before {
    content: '';
    position: absolute;
    left: 0;
    border-left-width: 1px;
    border-left-style: solid;
    top: -1.6rem;
    bottom: -1.6rem;
    border-left-color: #0000001f !important;
  }
}

// =============================================================================
// States
// =============================================================================

.c-ov-stepper__title {
  &.is-succes {
    color: theme('colors.ui.green1');
    cursor: pointer;
    .c-ov-stepper__stepnumber {
      background-color: theme('colors.ui.green1');
      color: theme('colors.grayscales.white');
    }
  }

  &.is-alert {
    color: theme('colors.ui.orange1');
    font-weight: bold;
    cursor: pointer;
    .c-ov-stepper__stepnumber {
      background-color: theme('colors.ui.orange1');
      color: theme('colors.grayscales.white');
    }
  }

  &.is-active {
    color: theme('colors.cta.base');
    font-weight: bold;
    .c-ov-stepper__stepnumber {
      background-color: theme('colors.cta.base');
      color: theme('colors.grayscales.white');
    }
  }

  &.is-upcoming {
    color: theme('colors.grayscales.gray3');
    .c-ov-stepper__stepnumber {
      background-color: theme('colors.grayscales.gray4');
      color: theme('colors.grayscales.gray3');
    }
  }

  &.no-pointer {
    cursor: default;
  }
}
