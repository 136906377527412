.c-no-data {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.c-no-data__content {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  color: theme('colors.grayscales.gray2');

  .c-no-data__title {
    @apply paragraph-bold;
    padding-bottom: 1rem;
    text-align: center;
  }

  .c-no-data__subtitle {
    @apply paragraph-base;
    text-align: center;
  }
}

.c-no-data__image {
  width: 100%;
  height: 19rem;
  align-self: center;
  background-image: url($globals-svg-path + 'undraw_empty.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
