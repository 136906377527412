.c-status-message {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.c-status-message__content {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-self: center;

  .c-status-message__title {
    @apply h2-base;
    padding-bottom: 1rem;
  }
}

.c-status-message__cta {
  padding-top: 4rem;
}

.c-status-message__image {
  width: 100%;
  height: 16.9rem;
  align-self: center;
  order: -1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  // background-image: url($globals-svg-path + 'status-message.svg');
}

@screen mobile {
  .c-status-message {
    flex-direction: row;
  }

  .c-status-message__image {
    width: 33.8rem;
    height: 31.2rem;
    order: 0;
  }
}
